import { useState, useEffect } from 'react';

const useShakeAnimation = (ref, trigger, duration = 820) => {
    const [isShaking, setIsShaking] = useState(false);

    // Effect for Intersection Observer
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsShaking(true);
                    setTimeout(() => setIsShaking(false), duration);
                }
            },
            { threshold: 0.25 } // Adjust as needed
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, duration]);

    // Effect for External Trigger
    useEffect(() => {
        if (trigger) {
            setIsShaking(true);
            const timer = setTimeout(() => setIsShaking(false), duration);
            return () => clearTimeout(timer);
        }
    }, [trigger, duration]);

    return isShaking;
};

export default useShakeAnimation;
