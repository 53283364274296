// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#buyer-footer {
    background-color: transparent;
}

#buyer-footer .buyer-footer-container {
    /* Additional styles for the container if needed */
}`, "",{"version":3,"sources":["webpack://./src/components/buyer/buyer-footer.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,kDAAkD;AACtD","sourcesContent":["#buyer-footer {\n    background-color: transparent;\n}\n\n#buyer-footer .buyer-footer-container {\n    /* Additional styles for the container if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
