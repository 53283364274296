// src/components/SellerLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';

const SellerLayout = ({ children, ...props }) => {
    return (
        <>
            <SellerHeader {...props} />
            <Outlet />
            <SellerFooter scrollToStartBox={props.scrollToStartBox} scrollToHowItWorks={props.scrollToHowItWorks} clientCallingHours={props.clientCallingHours} clientPhoneNumber={props.clientPhoneNumber} />
        </>
    );
};

export default SellerLayout;
