import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import GetOffer from './pages/GetOffer/GetOffer.js';
import reportWebVitals from './reportWebVitals';

// i18next imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
import enTranslations from './languages/en.json';
import frTranslations from './languages/fr.json';

// List of available languages
const availableLanguages = ['en', 'fr'];

// Detect the browser's language
const browserLanguage = navigator.language.split('-')[0];

// Set the language based on whether the browser's language is available
const defaultLanguage = availableLanguages.includes(browserLanguage) ? browserLanguage : 'fr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslations,
            },
            fr: {
                translation: frTranslations,
            },
        },
        lng: defaultLanguage,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <GetOffer />
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
