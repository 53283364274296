// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.initial-hidden {
    opacity: 0;
}

.fade-in-animation {
    animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
`, "",{"version":3,"sources":["webpack://./src/utils/useApplyClassOnView.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,OAAO,UAAU,EAAE;IACnB,KAAK,UAAU,EAAE;AACrB","sourcesContent":[".initial-hidden {\n    opacity: 0;\n}\n\n.fade-in-animation {\n    animation: fadeIn 1s ease forwards;\n}\n\n@keyframes fadeIn {\n    from { opacity: 0; }\n    to { opacity: 1; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
