import config from "../../config";
import customFetcher from "../../config/api/customFetcher";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify'

const useFetch = (
    actionType,
    options
) => {
    const [loading, toggle] = useState(options?.initLoading ?? !!options?.autoGetUrl ?? false);
    const [error, setError] = useState(null);

    const [data, setData] = useState(options?.mapper ? options?.mapper(options?.initialValue ?? null) : options?.initialValue ?? null);
    const afterCall = (result, params) => {
        let filteredData = options?.filter ? options?.filter(result) : result;
        let mapedData = options?.mapper
            ? options?.mapper(filteredData)
            : filteredData;
        setData(mapedData);
        options?.onSuccess?.(result, params);
    };
    const errorHandler = ({ err, statusCode, retryOptions }) => {
        if (statusCode === 401 && retryOptions?.retryCount === 0) {
            // router.push(routes.signin);
            // clearStorage();
            toggle(false);
            return;
        }

        if (retryOptions) {
            retryOptions.retryCount = statusCode ? config.dontRetryStatus.includes(statusCode) ? 0 : retryOptions.retryCount : retryOptions.retryCount
            if (retryOptions.retryCount > 0) {
                setTimeout(() => mutateAction(retryOptions), options?.retryDelay ?? 1500);
                return;
            }
        }
        if (!options?.customErrorHandler) {
            toast.error(err.message, { theme: "colored" })
        } else options?.customErrorHandler(err);
        options?.onFailed?.(err)
        setError(err.message)
        toggle(false);
    };
    const mutateAction = async ({ url, action, params, retryCount = 5 }) => {
        toggle(true);
        setError("");
        const retryEnabled = retryCount > 0;
        let res = null;
        try {
            let result = await action();
            if (result.ok) {
                let data = await result.json()
                if (!data.hasError) {
                    afterCall(data.result, params ? params : {
                        url
                    });
                    toggle(false);
                    res = (options?.mapper ? options.mapper(data.result) : data.result);
                }
                else {
                    let jsError = new Error(data.messages?.[0] ?? "Unknown error");
                    errorHandler({
                        err: jsError,
                        statusCode: 500,
                        retryOptions: {
                            url,
                            action,
                            retryCount: 0
                        }
                    });
                }
            }
            else {
                let data = await result?.json?.()
                let msg = "";
                if (data && data.hasError) {
                    msg = data.message?.[0] ?? "Unknown error"
                } else {
                    switch (result.status) {
                        case 500:
                            msg = "Our servers encountered an error. Please try again later";
                            break;
                        case 404:
                            msg = "Oops! We couldn't find the requested endpoint";
                            break;
                        case 403:
                            msg = "Permission denied!";
                            break;
                        default:
                            msg = "Network request failed"
                            break;
                    }
                }

                let jsError = new Error(msg);
                const rt = retryCount
                errorHandler({
                    err: jsError,
                    statusCode: result.status,
                    retryOptions: {
                        url,
                        action,
                        retryCount: rt === 0 ? rt : (rt - 1)
                    }
                });
            }
        } catch (err) {
            let rt = retryCount - 1;
            const msg = err && (err)?.message?.toString() || "Request failed, please check internet connection";
            if (msg.includes("aborted")) {
                rt = 0;
                setError(msg);
            }
            let jsError = new Error(msg);
            errorHandler({
                err: jsError,
                retryOptions: {
                    url,
                    action,
                    retryCount: rt
                }
            });
        } finally {
            if (!retryEnabled)
                toggle(false);
            return res;
        }
    }
    const mutate = {
        get: async (url, actionOptions) =>
            await mutateAction({
                url,
                action: () => customFetcher(`${url}`, {
                    method: "GET",
                    ...actionOptions
                }),
                retryCount: actionOptions?.retryCount ?? config.apiRequestRetryCount
            }),
        post: async (url, params, actionOptions) =>
            await mutateAction({
                url,
                params,
                action: () => customFetcher(`${url}`, {
                    method: "POST",
                    body: JSON.stringify(params),
                    ...actionOptions
                }),
                retryCount: actionOptions?.retryCount ?? config.apiRequestRetryCount
            }),
        put: async (url, params, actionOptions) =>
            await mutateAction({
                url,
                params,
                action: () => customFetcher(`${url}`, {
                    method: "PUT",
                    body: JSON.stringify(params),
                    ...actionOptions
                }),
                retryCount: actionOptions?.retryCount ?? 5
            }),
        patch: async (url, params, actionOptions) =>
            await mutateAction({
                url,
                params,
                action: () => customFetcher(`${url}`, {
                    method: "PATCH",
                    body: JSON.stringify(params),
                    ...actionOptions
                }),
                retryCount: actionOptions?.retryCount ?? 5
            }),
        delete: async (url, actionOptions) =>
            await mutateAction({
                url,
                action: () => customFetcher(`/${url}`, {
                    method: "DELETE",
                    ...actionOptions
                }),
                retryCount: actionOptions?.retryCount ?? 5
            })
    };
    useEffect(() => {
        if (options?.autoGetUrl) {
            mutate.get(options.autoGetUrl);
        }
    }, [])
    return { fetch: mutate[actionType], isLoading: loading, data, error };
};
export default useFetch;
