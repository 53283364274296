import React, { useState } from 'react';
import './index.css';
import { getData, storeData } from '../../../utils/localstorage';
import storageKeys from '../../../config/storageKeys';

function CookieConsentModal() {
    const [isVisible, setIsVisible] = useState(getData(storageKeys.useCoockiePermision) === true ? false : true);
  
    const handleAccept = () => {
        setIsVisible(false);
        storeData(storageKeys.useCoockiePermision, true)
    };

    const handleDeny = () => {
        setIsVisible(false);
        storeData(storageKeys.useCoockiePermision, false)
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-modal">
            <div className="cookie-text">
                <h4>Can we use cookies?</h4>
                <p>We use these cookies to make a better experience for our users. No selling, no fraud.</p>
            </div>
            <div className="cookie-buttons">
                <button onClick={handleAccept} className="cookie-btn accept">Allow</button>
                <button onClick={handleDeny} className="cookie-btn deny">Deny</button>
            </div>
        </div>
    );
}

export default CookieConsentModal;
