export const addresses = {
    getCarData: '/vehicle/vehicle-specs/makes/',
    getCarModel: '/vehicle/vehicle-specs/models/',
    getCarModelYears: '/vehicle/vehicle-specs/years/',
    sendPostalCode: "/seller/postal-code-submission",
    addressAutoComplete: '/general/address-autocomplete',
    getDetailPostalCode: '/general/postal-code-detail',
    sendVehicleProfile: '/vehicle/vehicle-profile/',
    getHighestOffer: '/vehicle/vehicle-profile',
    sendSellerInfo: '/seller/info/',
    acceptOffer: '/seller/accept-offer',
    rejectOffer: '/seller/reject-offer',
    fileUpload: '/seller/file-upload',
    auctionHighestOffer: '/seller/auction-highest-offer',
    generatedHighestOffer: '/seller/generated-highest-offer',
    getDistance:'/seller/get-distance',
    blog:'/blog/article/',
    slug:'/blog/article/slug/',
}