// CarContainer.js
import React, { useRef, useEffect, useState } from 'react';
import './CarContainer.css';
import smallCarIcon from '../../img/smallRedCarWithCircle64.png';
import speedometer32 from '../../img/speedometer32.png'
import location32 from '../../img/smallgreylocation.svg'
import calendar32 from '../../img/smallgreycalendar.svg'
import clock32 from '../../img/smallgreyclock.svg'
import phone32 from '../../img/smallgreyiphone.svg'
import rightArrow from '../../img/right-arrow.svg'
import profilePic from '../../img/user-profile.svg'
import carFront from '../../img/car-front-buyer.svg'
import { ReactComponent as SmallBlackArrow } from '../../img/right-arrow.svg';


const CarContainer = ({
                          model,
                          price,
                          distance,
                          date,
                          time,
                          postcode,
                          phone,
                          assignedBuyer,
                          assignedTowTruck,
                          appointmentStart,
                          appointmentEnd,
                          pickupAddress,
                      }) => {
    const sideImagesRef = useRef(null);
    const [dottedLineHeight, setDottedLineHeight] = useState(0);

    useEffect(() => {
        if (sideImagesRef.current) {
            const sideImagesHeight = sideImagesRef.current.offsetHeight;
            const newDottedLineHeight = sideImagesHeight - 70;
            setDottedLineHeight(newDottedLineHeight);
        }
    }, []);

    return (
        <div className="grid-car-container">
            <div className="car-container-background">
                <div className="car-container-contents-wrapper">
                    <button className="car-container info">
                        <div className="side-images-cont" ref={sideImagesRef}>
                            <div className="top-img">
                                <img src={carFront} alt="Small Car Icon" />
                                <div className="dotted-line" style={{ height: `${dottedLineHeight}px` }}></div>
                            </div>
                        </div>
                        <div className="car-details-wrapper">
                            <div className="car-details-container">
                                <div className="car-details">
                                    <div className="car-header">
                                        <div className="car-model">{model}</div>
                                        <div className="car-price">{price}</div>
                                    </div>
                                    <div className="car-info">
                                        <div className="car-detail">
                                            <img className="car-info-img" src={calendar32} alt="Calendar Icon" />
                                            {date}
                                        </div>
                                        <div className="car-detail">
                                            <img className="car-info-img" src={clock32} alt="Clock Icon" />
                                            {time}
                                        </div>
                                        <div className="car-detail">
                                            <img className="car-info-img" src={location32} alt="Location Icon" />
                                            {postcode}
                                        </div>
                                        <div className="car-detail">{distance}</div>
                                        <div className="car-detail">
                                            <a href={`tel:${phone}`} className="car-detail">
                                                <img className="car-info-img" src={phone32} alt="Phone Icon" />
                                                {phone}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </button>
                    <button className="car-container buyer">
                        <div className="side-images-cont">
                            <div className="buyer-prof-pic">
                                <img src={profilePic} alt="Buyer's Profile Pic" />
                            </div>
                        </div>
                        <div className="buyer-name">
                            <p>{assignedBuyer}</p>
                        </div>
                        <div className="status-indicator">Assigned</div>
                    </button>
                </div>
                <button className="assign-button">
          <span className="assign-text">
            Assign Buyer <SmallBlackArrow className="arrow-icon" />
          </span>
                </button>
            </div>
        </div>
    );
};

export default CarContainer;