import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './AboutUs.css';
import step1 from '../../img/step1.png';
import step2 from '../../img/step2.png';
import step3 from '../../img/step3.png';
import step4 from '../../img/step4.png';
import step5 from '../../img/step5.png';
import step6 from '../../img/step6.png';
import step7 from '../../img/step7.png';
import step8 from '../../img/step8.png';
import ecoFriendly from '../../img/ecoFriendly.png';
import relaxed from '../../img/relaxed-face.png';
import list from '../../img/list.png';
import priceTagColorless from '../../img/priceTagColorless.png';
import callIcon from "../../img/phoneSmallWhite.png";
import emailIcon from "../../img/mailSmallWhite.png";

function AboutUs({ setShouldScrollToStartBox, shouldScrollToStartBox }) {

    // Translation using the i18next lib
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');
    const navigate = useNavigate();
    const startBoxRef = useRef(null);

    const emojiMapping = {
        '{moneyEmoji}': String.fromCodePoint(0x1F4B0),
        '{recyclingEmoji}': String.fromCodePoint(0x267B),
        '{truckEmoji}': String.fromCodePoint(0x1F69A),
        '{checkMarkEmoji}': String.fromCodePoint(0x2705),
        '{computerEmoji}': String.fromCodePoint(0x1F4BB),
        '{magnifyingGlassEmoji}': String.fromCodePoint(0x1F50D),
    };

    // Language switch function
    function LanguageSwitcher() {
        const { i18n } = useTranslation();

        const switchLanguage = (language) => {
            i18n.changeLanguage(language);
            setSelectedLanguage(language);
        };
    };

    function replaceEmojis(str, emojiMapping) {
        let result = str;

        Object.keys(emojiMapping).forEach((key) => {
            const emoji = emojiMapping[key];
            const regex = new RegExp(key, 'g');
            result = result.replace(regex, emoji);
        });

        return result;
    }

    // General wait until function
    const waitUntil = (conditionFunction, checkInterval = 100) => {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (conditionFunction()) {
                    clearInterval(interval);
                    resolve();
                }
            }, checkInterval);
        });
    };

    // Code for the Get Your Offer button
    const scrollToStartBox = () => {
        navigate('/');
        setShouldScrollToStartBox(true);
    };

    return (
        <div className="parent-div">
            <h1 className="about-us-h1">{t('aboutUs.title')}</h1>

            {/*
               <div className="faq-container">
                <button className="faq-button">FAQ</button>
            </div>
            */}

            <p className="about-us-paragraph">
                <div className="about-us-paragraph-container">
                    <img src={list} alt="list" className="about-us-paragraph-container-icon" />
                    {t('aboutUs.paragraph1')}
                </div>
                <div className="about-us-paragraph-container">
                    <img src={priceTagColorless} alt="price tag" className="about-us-paragraph-container-icon" />
                    {t('aboutUs.paragraph2')}
                </div>
                <div className="about-us-paragraph-container">
                    <img src={relaxed} alt="relaxed face" className="about-us-paragraph-container-icon" />
                    {t('aboutUs.paragraph3')}
                </div>
                <div className="about-us-paragraph-container">
                    <img src={ecoFriendly} alt="eco friendly" className="about-us-paragraph-container-icon" />
                    {t('aboutUs.paragraph4')}
                </div>

            </p>
            <div className="contact-card">
                <button className="sidebar-button" aria-label="Call us">
                    <a href="tel:+15146481010" className="contact-button" aria-label="Call us">
                        <img src={callIcon} alt="" className="contact-button-icon" />
                        (514) 648-1010
                    </a>
                </button>
                <button className="sidebar-button" aria-label="Email us">
                    <a href="mailto:support@ecorecyclageqc.com" className="contact-button" aria-label="Email us">
                        <img src={emailIcon} alt="" className="contact-button-icon" />
                        support@ecorecyclageqc.com
                    </a>
                </button>
            </div>



            <button onClick={scrollToStartBox} className="instruction-button orange-button">
                {t('intro.instructions')}
            </button>

            <div className="start-box">
                {/* Content of start box */}
            </div>

            <div className="spacer1"></div>

            <div className="steps-container">
                <div className="steps-container-widescreen-modifier">
                    <div className="step">
                        <div className="step-number">{t('form.steps.header')} 1</div>
                        <h2>{t('form.steps.step1.title')}</h2>
                        <div>
                            <img src={step1} alt={t('form.steps.step1.title')} className="step1-image"/>
                            <p>{replaceEmojis(t('form.steps.step1.description'), emojiMapping)}</p>
                        </div>
                    </div>

                    <div className="step">
                        <div className="header-container">
                            <div className="step-number">{t('form.steps.header')} 2</div>
                            <h2>{t('form.steps.step2.title')}</h2>
                        </div>
                        <img src={step2} alt={t('form.steps.step2.title')} className="step2-image"/>
                        <p>{replaceEmojis(t('form.steps.step2.description'), emojiMapping)}</p>
                    </div>

                    <div className="step">
                        <div className="header-container">
                            <div className="step-number">{t('form.steps.header')} 3</div>
                            <h2>{t('form.steps.step3.title')}</h2>
                        </div>
                        <img src={step3} alt={t('form.steps.step3.title')} className="step3-image"/>
                        <p>{replaceEmojis(t('form.steps.step3.description'), emojiMapping)}</p>
                    </div>

                    <div className="step">
                        <div className="header-container">
                            <div className="step-number">{t('form.steps.header')} 4</div>
                            <h2>{t('form.steps.step4.title')}</h2>
                        </div>
                        <img src={step4} alt={t('form.steps.step4.title')} className="step4-image"/>
                        <p>{replaceEmojis(t('form.steps.step4.description'), emojiMapping)}</p>
                    </div>
                </div>

            </div>

            <div className="what-happens-next-header">
                <h2>
                    {t('form.whatHappensIfAccept')}
                </h2>
            </div>

            <div className="steps-container">
                <div className="steps-container-widescreen-modifier">
                    <div className="step">
                        <div className="step-number">{t('form.steps.header')} 5</div>
                        <h2>{t('form.steps.step5.title')}</h2>
                        <div>
                            <img src={step5} alt="automatic text message" className="step5-image" />
                            <p>{replaceEmojis(t('form.steps.step5.description'), emojiMapping)}</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">{t('form.steps.header')} 6</div>
                        <h2>{t('form.steps.step6.title')}</h2>
                        <div>
                            <img src={step6} alt="get a call from a specialist" className="step6-image" />
                            <p>{replaceEmojis(t('form.steps.step6.description'), emojiMapping)}</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">{t('form.steps.header')} 7</div>
                        <h2>{t('form.steps.step7.title')}</h2>
                        <div>
                            <img src={step7} alt="registration keys and driver's license" className="step7-image" />
                            <p>{replaceEmojis(t('form.steps.step7.description'), emojiMapping)}</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">{t('form.steps.header')} 8</div>
                        <h2>{t('form.steps.step8.title')}</h2>
                        <div>
                            <img src={step8} alt="scrap car sold" className="step8-image" />
                            <p>{replaceEmojis(t('form.steps.step8.description'), emojiMapping)}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default AboutUs;
