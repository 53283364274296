import { decrypt, encrypt } from "./encryption";
export const storeData = (key, data) => {
	try {
		localStorage.setItem(key, encrypt(data));
	} catch (err) {
		console.log("error in storing Data", err);
	}
};
export const getData = (key) => {
	try {
		let data = localStorage.getItem(key);
		if (!data) return null;
		return decrypt(data);
	} catch (err) {
		return null;
	}
};

export const clearStorage = () => {
	localStorage.clear()	
};
