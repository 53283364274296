/* global google */
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import '../GetOffer/GetOffer.css';
import './VehicleProfileHash.css';
import useScrollToRef from '../../utils/useScrollToRef';

import step6 from '../../img/step6.png';
import step7 from '../../img/step7.png';
import step8 from '../../img/step8.png';

import calendar from '../../img/calendar128.png';
import googleMaps from '../../img/googleMaps128.png';
import clock from '../../img/clock128.png';
import towTruckSmall from '../../img/towTruckSmall.png';

import saaq from '../../img/saaq.png';
import mto from '../../img/Mto_ontario_logo.png';
import ama from '../../img/ama.png';
import icbcSmall from '../../img/icbcSmall.png';
import sgi from '../../img/sgiSaskatchewan.png'

import { useTranslation } from 'react-i18next';
import useFetch from '../../shared/hooks/useFetch';
import { addresses } from '../../config/api/addresses';
import { toast } from 'react-toastify'

function BookTowtruck() {

    const [isLoadingScreen, setIsLoadingScreen] = useState(false);
    const [clientProvince, setClientProvince] = useState('');

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState({ from: '', to: '', dayTime: '' });
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    // Translation using the i18next lib
    const { t, i18n } = useTranslation();

    // Loading offer bullet point list variables
    const [showItem1, setShowItem1] = useState(false);
    const [showItem2, setShowItem2] = useState(false);
    const [showItem3, setShowItem3] = useState(false);
    const [showItem4, setShowItem4] = useState(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    // Page slides down
    const acceptOfferRef = useRef(null);

    const offerAcceptedRef = useRef(null);

    const whatHappensNextRef = useRef(null);

    const pickupDateRef = useRef(null);
    const pickupTimeRef = useRef(null);


    //FetchApi 
    const [searchParams] = useSearchParams();

    const { data: acutionHighestOffer, isLoading: loadingAcutionHighestOffer } = useFetch('get', {
        autoGetUrl: `${addresses.generatedHighestOffer}/${searchParams.get('vph')}`
    })

    const { data: addressSuggestions, fetch: fetchAddressSuggestions, isLoading: loadingAddressSuggestions } = useFetch('get', {
        mapper(result) { return result?.predictions }
    })

    const { fetch: sendAcceptOffer, isLoading: loadingAcceptOffer } = useFetch('post')


    const { data: postalCodeDetails, fetch: getDetailOfPostalCode } = useFetch('get')

    const handleSubmitAccept = async () => {
        // Turn on the loading screen
        setIsLoadingScreen(true);

        function newFormatDate(date) {
            const options = { month: 'long', day: 'numeric', timeZone: 'UTC' };
            let formattedDate = date.toLocaleDateString('en-US', options);

            // Extract the month and day components separately
            const [month, day] = formattedDate.split(' ');

            // Adding ordinal suffix to day
            const dayNum = parseInt(day, 10); // Convert string to number
            let suffix = 'th';
            if (dayNum % 10 === 1 && dayNum !== 11) suffix = 'st';
            else if (dayNum % 10 === 2 && dayNum !== 12) suffix = 'nd';
            else if (dayNum % 10 === 3 && dayNum !== 13) suffix = 'rd';

            // Return formatted date with comma and ordinal suffix
            return `${month}, ${dayNum}${suffix}`;
        }

        // Function to format time in AM/PM format
        function formatTime(date) {
            // const options = { hour: 'numeric', hour12: true, timeZone: 'UTC' };
            const regex = /(\d+:\d+):\d+\s*([AP]M)/i;
            // Replace function to format it into a simpler 'h:mma' format
            return date.toLocaleTimeString('en-US').replace(regex, (match, hourMinute, amPm) => {
                return `${hourMinute}${amPm.toLowerCase()}`;
            });
        }

        // const timeForEmail = getCurrentTime();
        // setCurrentTime(timeForEmail);
        const convertToISOString = (date, time) => {
            const dateTimeString = `${date}T${time}`;
            const dateObject = new Date(dateTimeString);
            return dateObject.toISOString();
        }

        const timeFrom = convertToISOString(selectedDate, selectedTime.from)
        const timeTo = convertToISOString(selectedDate, selectedTime.to)

        const acceptOfferRes = await sendAcceptOffer(addresses.acceptOffer, {

            "buyer_offer_id": acutionHighestOffer.id,
            "appointment_details": {
                "address": {
                    "address_str": address
                },
                "pickup_time_from": timeFrom,
                "pickup_time_to": timeTo,
                "str_pickup_date": newFormatDate(new Date(timeFrom)),
                "str_pickup_time": `${formatTime(new Date(timeFrom))} and ${formatTime(new Date(timeTo))}`,
            }

        })

        if (acceptOfferRes) {
            // Rest of your code
            toast(t('form.pickupConfirmation', { date: formatDate(selectedDate, i18n.language), offeredPrice: acutionHighestOffer.offered_price, make: acutionHighestOffer.vehicle_profile.vehicle_specs.make, model: acutionHighestOffer.vehicle_profile.vehicle_specs.model }));
            // resetForm();

            setIsFormSubmitted(true);

            // Turn off the loading screen
            setIsLoadingScreen(false);

            // scroll to what happens next
            setTimeout(() => {
                if (whatHappensNextRef.current) {
                    // Calculate the offset by 0.08 times the viewport height
                    const offset = window.innerHeight * 0.08;

                    // Get the top position of the element and subtract the calculated offset
                    const elementPosition = whatHappensNextRef.current.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - offset;

                    // Scroll to the offset position
                    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
                }
            }, 400);
        }

    };


    // Function to handle to date converison to format Jul 3rd 2023
    const formatDate = (dateString, language) => {
        const date = new Date(`${dateString}T12:00:00`); // Set the time to noon
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString(language, options);
    };

    // Handler for address input and autofill suggestion
    const handleAddressChange = async (event) => {
        const inputValue = event.target.value;
        setAddress(inputValue); // Update your address state

        if (inputValue.length > 0) { // Check if the input value is not empty
            const predictions = await fetchAddressSuggestions(`${addresses.addressAutoComplete}?input=${encodeURIComponent(inputValue)}`)
            if (predictions) {
                setSuggestions(predictions); // Assuming the API returns predictions
            }
           
        } else {
            setSuggestions([]); // Clear suggestions if the input is empty
        }
    };
    // Handler for when a suggestion is clicked
    const handleSuggestionClick = (suggestion) => {
        setAddress(suggestion.description); // Update the input field with the suggestion
        setSuggestions([]); // Clear suggestions after selection
    };

    function replaceEmojis(str, emojiMapping) {
        let result = str;

        Object.keys(emojiMapping).forEach((key) => {
            const emoji = emojiMapping[key];
            const regex = new RegExp(key, 'g');
            result = result.replace(regex, emoji);
        });

        return result;
    }

    useScrollToRef(selectedDate, pickupDateRef);
    useScrollToRef(selectedTime, pickupTimeRef);

    // Fix page not loading at the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to compute the earliest pickup day (today or tomorrow)
    const now = new Date();
    const currentHour = now.getHours();
    function checkAvailableTime() {
        const now = new Date(); // Gets the current date and time
        const currentHour = now.getHours(); // Extracts the hour from the current time

        if (currentHour >= 16) { // 16 corresponds to 4 PM in 24-hour format
            return t('form.tomorrow');
        } else {
            return t('form.today')
        }
    }

    // Date picker modifications
    const [showDatePicker, setShowDatePicker] = useState(false);

    function getDateString(daysFromToday) {
        const date = new Date();
        date.setDate(date.getDate() + daysFromToday);

        // Adjust for the timezone offset
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.getTime() - userTimezoneOffset);

        return adjustedDate.toISOString().split('T')[0];
    }

    const getProvience = async () => {
        const customerProvinece = {
            quebec: "SAAQ",
            ontario: "MTO",
            "british columbia": "ICBC",
            alberta: "AMA",
            manitoba: "MPI",
            saskatchewan: "SGI",
        }


        const postalDetail = await getDetailOfPostalCode(`${addresses.getDetailPostalCode}/${acutionHighestOffer.vehicle_profile.postal_code.replace(" ", "")}`, {})
        if (postalDetail) {
            if (customerProvinece[postalDetail.province]) {
                setClientProvince(customerProvinece[postalDetail.province])
            }
        }
    }

    // Show the offer-notes sequentially
    useEffect(() => {
        if (acutionHighestOffer?.offered_price !== null) {
            setShowItem1(false);
            setShowItem2(false);
            setShowItem3(false);
            setShowItem4(false);

            setTimeout(() => setShowItem1(true), 300); // Delay for item 1
            setTimeout(() => setShowItem2(true), 600); // Delay for item 2
            setTimeout(() => setShowItem3(true), 900); // Delay for item 3
            setTimeout(() => setShowItem4(true), 1200); // Delay for item 4
        }
    }, [acutionHighestOffer?.offered_price]);

    useEffect(() => {
        if (acutionHighestOffer) {
            getProvience()
        }
    }, [acutionHighestOffer]);

    // remove the items if the price is null
    useEffect(() => {
        if (acutionHighestOffer?.offered_price === null) {
            setShowItem1(false);
            setShowItem2(false);
            setShowItem3(false);
            setShowItem4(false);
        }
    }, [acutionHighestOffer?.offered_price]);

    const emojiMapping = {
        '{moneyEmoji}': String.fromCodePoint(0x1F4B0),
        '{recyclingEmoji}': String.fromCodePoint(0x267B),
        '{truckEmoji}': String.fromCodePoint(0x1F69A),
        '{checkMarkEmoji}': String.fromCodePoint(0x2705),
        '{computerEmoji}': String.fromCodePoint(0x1F4BB),
        '{magnifyingGlassEmoji}': String.fromCodePoint(0x1F50D),
    };

    return (

        <div className="parent-div booking">
            <div className="booking-start-area">
                <div className="booking-header">
                    <h2>{t('booking.header')}</h2>
                </div>
                <img src={towTruckSmall} alt="Tow Truck" className="tow-truck-img" />
            </div>



            {isFormSubmitted === false && acutionHighestOffer && (
                <>
                    <div ref={acceptOfferRef} className="offer-display-container">
                        <div className="offer-text-and-notes-container booking">

                            {acutionHighestOffer && (
                                <>
                                    <div className="counterOffer-sentence-container">
                                        <span className="counter-offer-text">{t('booking.counterOfferString')}</span>
                                        <span className="MMY-container">{acutionHighestOffer.vehicle_profile.vehicle_specs.year} </span>
                                        <span className="MMY-container"> {acutionHighestOffer.vehicle_profile.vehicle_specs.make} </span>
                                        <span className="MMY-container">{acutionHighestOffer.vehicle_profile.vehicle_specs.model} </span>
                                        <p className="counter-offer-text-container">
                                            <span className="counter-offer-text">{t('booking.counterOfferString2')}&nbsp;</span>
                                            <span className="offer-container">${Math.floor(acutionHighestOffer.offered_price)}</span>
                                        </p>

                                    </div>
                                    <ul className="offer-notes">
                                        <li className={showItem1 ? "show-item-1" : ""}>
                                            {t('form.offerNotesItem1.1')}
                                            <span className="underline"> {checkAvailableTime()} </span>
                                            {t('form.offerNotesItem1.2')}
                                        </li>
                                        <li className={showItem2 ? "show-item-2" : ""}>
                                            {t('form.offerNotesItem2')}
                                        </li>
                                        <li className={showItem3 ? "show-item-3" : ""}>
                                            {t('form.offerNotesItem4')}
                                        </li>
                                        {clientProvince !== '' ? <li className={showItem4 ? "show-item-4" : ""}>
                                            {t('form.offerNotesItem3&1')}
                                            {/* {clientProvince === 'AB' ? 'AMA ' :
                                                                        clientProvince === 'BC' ? 'ICBC ' :
                                                                            clientProvince === 'ON' ? 'MTO ' :
                                                                                clientProvince === 'SK' ? 'SGI ' :
                                                                                    'SAAQ '} */}
                                            {`${clientProvince} `}
                                            {t('form.offerNotesItem3&2')}
                                            <img
                                                src={
                                                    clientProvince === 'AMA' ? ama :
                                                        clientProvince === 'ICBC' ? icbcSmall :
                                                            clientProvince === 'MTO' ? mto :
                                                                clientProvince === 'SGI' ? sgi :
                                                                    saaq
                                                }
                                                alt={
                                                    clientProvince === 'AMA' ? "ama logo" :
                                                        clientProvince === 'ICBC' ? "icbc logo" :
                                                            clientProvince === 'MTO' ? "mto logo" : // Alt text for the MTO logo
                                                                "saaq logo"
                                                }
                                                className={
                                                    clientProvince === 'AMA' ? "ama-icon" :
                                                        clientProvince === 'ICBC' ? "icbc-icon" :
                                                            clientProvince === 'MTO' ? "mto-icon" : // Class name for the MTO logo
                                                                "saaq-icon"
                                                }
                                            />
                                        </li> : null}



                                    </ul>
                                </>
                            )}

                        </div>
                    </div>
                    <div>
                        {(
                            <div className="offer-response-container">
                                <h2 className="box-title end">{t('booking.bookyourtowtruck')}</h2>
                                <div ref={offerAcceptedRef} className="schedule-and-submit-container">
                                    <div className="new-question-container" ref={pickupDateRef}>
                                        <label>
                                            {t('form.pickupDate')}
                                            <img src={calendar} alt="Calendar Icon" className="calendar-icon" />
                                        </label>
                                        <div className="date-button-container">

                                            <button
                                                className={`date-button ${selectedDate === getDateString(0) ? 'date-button-selected' : ''}`}
                                                onClick={() => { setSelectedDate(getDateString(0)); setShowDatePicker(false); }}
                                            >
                                                {t('form.today')}
                                            </button>

                                            <button
                                                className={`date-button ${selectedDate === getDateString(1) ? 'date-button-selected' : ''}`}
                                                onClick={() => { setSelectedDate(getDateString(1)); setShowDatePicker(false); }}
                                            >
                                                {t('form.tomorrow')}
                                            </button>
                                            {showDatePicker ? (
                                                <input
                                                    className="input-date"
                                                    type="date"
                                                    min={getDateString(2)}
                                                    value={selectedDate}
                                                    onChange={(e) => setSelectedDate(e.target.value)}
                                                />
                                            ) : (
                                                <button
                                                    className={`date-button ${selectedDate && selectedDate !== getDateString(0) && selectedDate !== getDateString(1) ? 'date-button-selected' : ''}`}
                                                    onClick={() => setShowDatePicker(true)}
                                                >
                                                    {t('form.chooseALaterDate')}
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    {selectedDate && (
                                        <div className="new-question-container" ref={pickupTimeRef}>
                                            <label
                                                htmlFor="pickup-time">{t('form.pickupTime')}
                                                <img src={clock} alt="Clock Icon" className="clock-icon" />
                                            </label>
                                            <div className="time-button-container">
                                                {/* Render the "morning" button if the user didn't select "today" */}
                                                {(selectedDate !== getDateString(0) || currentHour < 11) && (
                                                    <button
                                                        className={`time-button ${selectedTime.dayTime === 'morning' ? 'time-button-selected' : ''}`}
                                                        onClick={() => setSelectedTime({ from: '08:00', to: '12:00', dayTime: 'morning' })}
                                                    >
                                                        {t('form.morning')}
                                                    </button>
                                                )}

                                                {/* Render the "afternoon" button if the user didn't select "today" */}
                                                {(selectedDate !== getDateString(0) || currentHour < 15) && (
                                                    <button
                                                        className={`time-button ${selectedTime.dayTime === 'afternoon' ? 'time-button-selected' : ''}`}
                                                        onClick={() => setSelectedTime({ from: '12:00', to: '16:00', dayTime: 'afternoon' })}
                                                    >
                                                        {t('form.afternoon')}
                                                    </button>
                                                )}

                                                {selectedDate !== getDateString(0)}
                                                <button
                                                    className={`time-button ${selectedTime.dayTime === 'evening' ? 'time-button-selected' : ''}`}
                                                    onClick={() => setSelectedTime({ from: '16:00', to: '20:00', dayTime: 'evening' })}
                                                >
                                                    {t('form.evening')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {selectedTime.dayTime !== '' && (
                                        <div className="new-question-container">
                                            <label htmlFor="address">{t('form.address')}
                                                <img src={googleMaps} alt="Google Maps Icon" className="googleMaps-icon" />
                                            </label>
                                            <input
                                                className="input-address"
                                                type="text"
                                                id="address"
                                                value={address}
                                                onChange={handleAddressChange} // Use the event handler here
                                            // onFocus={handleAddressFocus}
                                            />
                                            {suggestions.length > 0 && (
                                                <ul className="suggestions-dropdown">
                                                    {suggestions.slice(0, 3).map((suggestion) => (
                                                        <li
                                                            key={suggestion.place_id}
                                                            onClick={() => handleSuggestionClick(suggestion)}
                                                        >
                                                            {suggestion.description}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}

                                    {address !== '' && <button
                                        className="submit-button"
                                        onClick={handleSubmitAccept}
                                    >
                                        {t('form.submit')}
                                    </button>}
                                    {isLoadingScreen && (
                                        <div className="loading-screen">
                                            <div className="loading-overlay"></div>
                                            <div className="loading-container">
                                                <div className="loading-content">
                                                    <div className='loading'>
                                                        <span className="gear">⚙️</span>
                                                        {t('form.loadingSubmission')}
                                                        <span className="gear">⚙️</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}

                    </div>
                </>
            )}


            <>
                <div ref={whatHappensNextRef} className="what-happens-next-header">
                    <h2>
                        {t('booking.whatHappensAfterIBook')}
                    </h2>
                </div>
                <div className="steps-container">
                    <div className="steps-container-widescreen-modifier">

                        <div className="step">
                            <div className="step-number">{t('form.steps.header')} 1</div>
                            <h2>{t('form.steps.step6.title')}</h2>
                            <div>
                                <img src={step6} alt="get a call from a specialist" className="step6-image" />
                                <p>{replaceEmojis(t('form.steps.step6.description'), emojiMapping)}</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="step-number">{t('form.steps.header')} 2</div>
                            <h2>{t('form.steps.step7.title')}</h2>
                            <div>
                                <img src={step7} alt="registration keys and driver's license" className="step7-image" />
                                <p>{replaceEmojis(t('form.steps.step7.description'), emojiMapping)}</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="step-number">{t('form.steps.header')} 3</div>
                            <h2>{t('form.steps.step8.title')}</h2>
                            <div>
                                <img src={step8} alt="scrap car sold" className="step8-image" />
                                <p>{replaceEmojis(t('form.steps.step8.description'), emojiMapping)}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </div>
    );
}

export default BookTowtruck;