import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../pages/GetOffer/GetOffer.css';
import './seller-footer.css';
import callIcon from "../../img/phoneSmallWhite.png";
import emailIcon from "../../img/mailSmallWhite.png";

const SellerFooter = ({ selectedLanguageProp, clientPhoneNumber, clientCallingHours, scrollToHowItWorks, scrollToStartBox }) => { // Renamed prop to avoid conflict
    // Translation using the i18next lib
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');

    useEffect(() => {
        // Function to call when language changes
        const handleLanguageChange = (lng) => {
            setSelectedLanguage(lng);
        };

        // Add event listener to i18next
        i18n.on('languageChanged', handleLanguageChange);

        // Clean up the event listener
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    // Create a new Date object
    const currentDate = new Date();

    // Get the current day as a string ('Monday', 'Tuesday', etc.)
    // Adjust the locale as per your application's internationalization settings
    const currentDayString = currentDate.toLocaleString('en-us', { weekday: 'long' });

    // Initialize state variables
    const [startHour, setStartHour] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [formattedHours, setFormattedHours] = useState('');

    // Function to format hours based on language
    const formatHours = (start, end, language) => {
        if (language === 'en') {
            return `${start}am to ${end}pm`;
        } else if (language === 'fr') {
            return `${start}h à ${end}h`;
        }
        // Default format (you can adjust this as needed)
        return `${start} - ${end}`;
    };

    // Update state variables and formatted string when clientCallingHours or language changes
    useEffect(() => {
        if (clientCallingHours) {
            const [newStartHour, newEndHour] = clientCallingHours.split(',').map(Number);
            setStartHour(newStartHour);
            setEndHour(newEndHour);

            // Format the hours based on the selected language
            const formatted = formatHours(newStartHour, newEndHour, selectedLanguage);
            setFormattedHours(formatted);
        }
    }, [clientCallingHours, selectedLanguage]);

    return (
        <footer>
            <h1 className="company-name-footer">{t('header.companyName')}</h1>
            <div className="footer-content-container">
                <div className="quick-access-container">
                    {/* List Header */}
                    <div className="quick-access-header">Quick Access</div>

                    {/* List Items */}
                    <ul className="quick-access-list">
                        <li className="quick-access-item" style={{ cursor: "pointer" }} onClick={scrollToHowItWorks}>How it works</li>
                        <li className="quick-access-item"><a style={{ textDecoration: "none", color: "white" }} target='_blank' href='https://eco-recycle.ca/blog/'>Blogs</a></li>
                        <li className="quick-access-item"style={{ cursor: "pointer" }}>Privacy Policy</li>
                        <li className="quick-access-item" onClick={scrollToStartBox} style={{ cursor: "pointer" }}>Get an Offer</li>
                    </ul>
                </div>
                <div className="footer-contact-card">
                    <button className="sidebar-button footer" aria-label="Call us">
                        {/* <a href={`tel:${clientPhoneNumber || '514-648-1010'}`} className='contact-button footer' aria-label="Call us"> */}
                        <a href={`tel:${'438-346-9331'}`} className='contact-button footer' aria-label="Call us">
                            <img src={callIcon} alt="Call Icon" className="contact-button-icon footer" />
                            {/* {clientPhoneNumber || '514-648-1010'} */}
                            {'438-346-9331'}
                        </a>
                    </button>

                    <button className="sidebar-button footer" aria-label="Email us">
                        <a href="mailto:support@ecorecyclageqc.com" className="contact-button footer" aria-label="Email us">
                            <img src={emailIcon} alt="" className="contact-button-icon footer" />
                            support@ecorecyclageqc.com
                        </a>
                    </button>
                </div>
                <div className="contact-info footer">
                    <div className='hours-info'>
                        <h2>{t('footer.Hours')}</h2>
                        <ul className="hours-list">
                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                                currentDayString.toLowerCase() === day.toLowerCase() && (
                                    <li key={day}>
                                        <span className="day-component">
                                            ⏰ {t(`footer.${day}`)}
                                        </span>
                                        <span className="time-component">
                                            {/* {formattedHours} */}
                                            9AM - 5PM
                                        </span>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default SellerFooter;
