import { useEffect } from 'react';

// Custom hook for scrolling
const useScrollToRef = (shouldScroll, ref) => {
    useEffect(() => {
        if (shouldScroll && ref.current) {
            // Calculate the offset by 0.07 times the viewport height
            const offset = window.innerHeight * 0.10;

            // Get the top position of the element and subtract the calculated offset
            const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            // Scroll to the offset position
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
    }, [shouldScroll, ref]);
};

export default useScrollToRef;
