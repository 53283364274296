import React, { useState } from 'react';
import './index.css';
import { getData, storeData } from '../../../utils/localstorage';
import storageKeys from '../../../config/storageKeys';
import { useTranslation } from 'react-i18next';

function CookieConsentModal() {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(getData(storageKeys.useCoockiePermision) === true ? false : true);

    const handleAccept = () => {
        setIsVisible(false);
        storeData(storageKeys.useCoockiePermision, true)
    };

    const handleDeny = () => {
        setIsVisible(false);
        storeData(storageKeys.useCoockiePermision, false)
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-modal">
            <div className="cookie-text">
                <h4>{t('cookie.title')}</h4>
                <p>{t('cookie.body')}</p>
            </div>
            <div className="cookie-buttons">
                <button onClick={handleAccept} className="cookie-btn accept">{t('cookie.accept')}</button>
                <button onClick={handleDeny} className="cookie-btn deny">{t('cookie.reject')}</button>
            </div>
        </div>
    );
}

export default CookieConsentModal;
