import React from 'react';
import '../GetOffer/GetOffer.css';
import './PrivacyAndPolicy.css';
import { useTranslation } from 'react-i18next';

function PrivacyAndPolicy() {
    const { t } = useTranslation();

    return (
        <div className="privacy-policy-container">
            <div className="privacy-policy-content">
                <h1>{t('privacyPolicy.title')}</h1>
                <p className="last-updated">{t('privacyPolicy.lastUpdated')}</p>

                <h2>{t('privacyPolicy.section1.title')}</h2>
                <p>{t('privacyPolicy.section1.description')}</p>
                <ul>
                    <li>{t('privacyPolicy.section1.items.name')}</li>
                    <li>{t('privacyPolicy.section1.items.phone')}</li>
                    <li>{t('privacyPolicy.section1.items.ymm')}</li>
                    <li>{t('privacyPolicy.section1.items.condition')}</li>
                    <li>{t('privacyPolicy.section1.items.location')}</li>
                </ul>

                <h2>{t('privacyPolicy.section2.title')}</h2>
                <p>{t('privacyPolicy.section2.description')}</p>
                <ul>
                    <li>{t('privacyPolicy.section2.items.offerCalculation')}</li>
                    <li>{t('privacyPolicy.section2.items.communication')}</li>
                    <li>{t('privacyPolicy.section2.items.pickupCoordination')}</li>
                    <li>{t('privacyPolicy.section2.items.followUp')}</li>
                </ul>

                <h2>{t('privacyPolicy.section3.title')}</h2>
                <p>{t('privacyPolicy.section3.description')}</p>
                <ul>
                    <li>{t('privacyPolicy.section3.items.buyerContact')}</li>
                    <li>{t('privacyPolicy.section3.items.negotiation')}</li>
                </ul>

                <h2>{t('privacyPolicy.section4.title')}</h2>
                <p>{t('privacyPolicy.section4.description')}</p>
                <ul>
                    <li>{t('privacyPolicy.section4.items.salePickup')}</li>
                    <li>{t('privacyPolicy.section4.items.ownershipIssues')}</li>
                </ul>
                <p>{t('privacyPolicy.section4.closing')}</p>

                <h2>{t('privacyPolicy.section5.title')}</h2>
                <p>{t('privacyPolicy.section5.description')}</p>

                <h2>{t('privacyPolicy.section6.title')}</h2>
                <p>{t('privacyPolicy.section6.description')}</p>

                <h2>{t('privacyPolicy.section7.title')}</h2>
                <p>{t('privacyPolicy.section7.description')}</p>

                <h2>{t('privacyPolicy.section8.title')}</h2>
                <p>{t('privacyPolicy.section8.description')}</p>
                <ul>
                    <li>{t('privacyPolicy.section8.items.access')}</li>
                    <li>{t('privacyPolicy.section8.items.corrections')}</li>
                    <li>{t('privacyPolicy.section8.items.deletion')}</li>
                    <li>{t('privacyPolicy.section8.items.withdrawConsent')}</li>
                </ul>

                <h2>{t('privacyPolicy.section9.title')}</h2>
                <p>{t('privacyPolicy.section9.description')}</p>

                <p>{t('privacyPolicy.contact')}</p>
            </div>
        </div>
    );
}

export default PrivacyAndPolicy;
