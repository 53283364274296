// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DispatcherDash.css */
.dispatcher-dash {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
    font-family: 'Raleway', sans-serif;

}

.car-container-wrapper {
    width: 100%;
    height: max-content;
    max-width: 800px; /* Adjust the max-width as needed */
    margin-bottom: 20px;
}

.buyable-cars-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.small-car-column {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-car-column:first-child {
    margin-right: 20px;
}

@media screen and (max-width: 800px) {
    .car-container-wrapper {
        grid-template-columns: 1fr;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/BuyerDashboards/DispatcherDash.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,kCAAkC;;AAEtC;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,gBAAgB,EAAE,mCAAmC;IACrD,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["/* DispatcherDash.css */\n.dispatcher-dash {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: max-content;\n    font-family: 'Raleway', sans-serif;\n\n}\n\n.car-container-wrapper {\n    width: 100%;\n    height: max-content;\n    max-width: 800px; /* Adjust the max-width as needed */\n    margin-bottom: 20px;\n}\n\n.buyable-cars-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n    background-color: #ffffff;\n    width: 100%;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n.small-car-column {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.small-car-column:first-child {\n    margin-right: 20px;\n}\n\n@media screen and (max-width: 800px) {\n    .car-container-wrapper {\n        grid-template-columns: 1fr;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
