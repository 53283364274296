import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.css';
import { useTranslation } from 'react-i18next';

const CityCarousel = () => {
    const { t } = useTranslation();
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    // Define your city keys for translations
    const cityKeys = ['intro.montreal', 'intro.toronto', 'intro.calgary', 'intro.ottawa', 'intro.saskatoon', ];
    const totalSlides = cityKeys.length;

    const getSlideClass = (index) => {
        let middleSlide = (currentSlide + 1) % totalSlides;
        if (currentSlide === totalSlides - 1) {
            middleSlide = 0;
        }
        return index === middleSlide ? styles.activeSlide : styles.citySlide;
    };

    return (
        // <div className={`${styles.carouselContainer} ${styles.fadeInUpCarousel}`}>
        <div className={`${styles.carouselContainer}`}>
            <Slider {...settings}>
                {cityKeys.map((key, index) => (
                    <div key={index} className={getSlideClass(index)}>
                        <h3 className={styles.cityName}>{t(key)}</h3>
                    </div>
                ))}
            </Slider>
        </div>

    );
};

export default CityCarousel;
