// LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        error: null,
    });

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        error: null,
                    });
                },
                (error) => {
                    setLocation((prevState) => ({
                        ...prevState,
                        error: error.message,
                    }));
                }
            );
        } else {
            setLocation((prevState) => ({
                ...prevState,
                error: "Geolocation is not supported by this browser.",
            }));
        }
    };

    // useEffect(() => {
    //     getLocation();
    // }, []);

    return (
        <LocationContext.Provider value={{ location, getLocation }}>
            {children}
        </LocationContext.Provider>
    );
};
