// ContainerSelector.js
import React, { useRef, useEffect, useState } from 'react';
import './container-selector.css';
import carDollar from '../../img/car-dollar-svg.svg';
import towTruck from '../../img/towtruck-svg.svg';
import phoneCall from '../../img/phone-call-svg.svg';

const ContainerSelector = ({ topNavStatus, setTopNavStatus, selectedBuyer, setSelectedBuyer }) => {

    return (
        <div className="nav-wrapper">
            <div className="top-navigation">
                <button
                    onClick={() => setTopNavStatus('toCall')}
                    className={topNavStatus === 'toCall' ? 'active' : ''}
                >
                    <span>Pending</span>
                </button>
                <button
                    onClick={() => setTopNavStatus('toBuy')}
                    className={topNavStatus === 'toBuy' ? 'active' : ''}
                >
                    <span>Buy</span>
                </button>
                <button
                    onClick={() => setTopNavStatus('bought')}
                    className={topNavStatus === 'bought' ? 'active' : ''}
                >
                    <span>Tow</span>
                </button>
            </div>
            <div className="sub-navigation">
                <button
                    onClick={() => setSelectedBuyer('all')}
                    className={selectedBuyer === 'all' ? 'active' : ''}
                >
                    <span>All</span>
                </button>
                <button
                    onClick={() => setSelectedBuyer('buyer1')}
                    className={selectedBuyer === 'buyer1' ? 'active' : ''}
                >
                    <span>Buyer 1</span>
                </button>
                <button
                    onClick={() => setSelectedBuyer('buyer2')}
                    className={selectedBuyer === 'buyer2' ? 'active' : ''}
                >
                    <span>Buyer 2</span>
                </button>
            </div>
        </div>
    )
        ;
};

export default ContainerSelector;