// BuyerHeader.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './buyer-header.css';
import './buyer-sidebar.css'

import towTruckSmall from '../../img/towTruckSmall.png';
import smallTransparentLogo from '../../img/smallTransparentLogo.png';
import smallPriceTag from '../../img/priceTagSmall.png';
import callIcon from '../../img/phoneSmallWhite.png';
import emailIcon from '../../img/mailSmallWhite.png';
import getYourOfferBW from '../../img/car_dollor_bw_icon.png';
import howItWorksBW from '../../img/towing_bw_icon.png';
import aboutUsBW from '../../img/recycling_bw_icon.png';

const BuyerHeader = ({ selectedLanguage, setSelectedLanguage }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { t } = useTranslation();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleGetAnOffer = () => {
        // Add logic for toggling "Get an Offer" section
    };

    const toggleHowItWorks = () => {
        // Add logic for toggling "How It Works" section
    };

    const toggleAboutUs = () => {
        // Add logic for toggling "About Us" section
    };

    return (
        <div className="header-band">
            <div className="company-name">
                <h1 className="h1-header">{t('header.companyName')}</h1>
            </div>
            {/* LanguageSwitcher component */}
            <div className="widescreen-nav-bar">
                {/* Widescreen navigation buttons */}
            </div>

            <div className={`hamburger-menu ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                <div></div> {/* Line 1 */}
                <div></div> {/* Line 2 */}
                <div></div> {/* Line 3 */}
            </div>

            <div className={`sidebar ${isSidebarOpen ? 'visible' : ''} buyer`}>
                <nav className="sidebar-nav">
                    {/* Sidebar navigation buttons */}
                </nav>

                <div className="sidebar-header">
                    <h1>{t('header.companyName')}</h1>
                </div>

                {/* Contact buttons */}
            </div>
        </div>
    );
};

export default BuyerHeader;