// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex-grow: 1; /* Makes the content div take up the most space */
    padding: 20px;
    overflow-y: auto; /* Enables scrolling if content overflows */
}

.content h1 {
    margin-top: 50px;
    font-size: 32px;
    font-weight: 700;
    font-family: Raleway;
    margin-bottom: 0.5em; /* Space between title and text */
    text-align: left;
}

.content p {
    margin: 0;
    text-align: justify; /* Justifies the text for better readability */
    white-space: pre-wrap; /* Wraps text and preserves whitespace */
}

.footer {
    padding: 10px;
    display: flex;
    justify-content: space-around; /* Spreads the buttons evenly */
}

button {
    padding: 10px 20px;
    cursor: pointer; /* Gives a pointer cursor on hover over the buttons */
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyAndPolicy/PrivacyAndPolicy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY,EAAE,iDAAiD;IAC/D,aAAa;IACb,gBAAgB,EAAE,2CAA2C;AACjE;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,oBAAoB,EAAE,iCAAiC;IACvD,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,mBAAmB,EAAE,8CAA8C;IACnE,qBAAqB,EAAE,wCAAwC;AACnE;;AAEA;IACI,aAAa;IACb,aAAa;IACb,6BAA6B,EAAE,+BAA+B;AAClE;;AAEA;IACI,kBAAkB;IAClB,eAAe,EAAE,qDAAqD;AAC1E","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.content {\n    flex-grow: 1; /* Makes the content div take up the most space */\n    padding: 20px;\n    overflow-y: auto; /* Enables scrolling if content overflows */\n}\n\n.content h1 {\n    margin-top: 50px;\n    font-size: 32px;\n    font-weight: 700;\n    font-family: Raleway;\n    margin-bottom: 0.5em; /* Space between title and text */\n    text-align: left;\n}\n\n.content p {\n    margin: 0;\n    text-align: justify; /* Justifies the text for better readability */\n    white-space: pre-wrap; /* Wraps text and preserves whitespace */\n}\n\n.footer {\n    padding: 10px;\n    display: flex;\n    justify-content: space-around; /* Spreads the buttons evenly */\n}\n\nbutton {\n    padding: 10px 20px;\n    cursor: pointer; /* Gives a pointer cursor on hover over the buttons */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
