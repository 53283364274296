// src/components/BuyerLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import BuyerHeader from './BuyerHeader';
import BuyerFooter from './BuyerFooter';

const BuyerLayout = ({ children }) => {
    return (
        <div>
            <BuyerHeader />
            <Outlet />
            <BuyerFooter />
        </div>
    );
};

export default BuyerLayout;
