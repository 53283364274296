import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadIcon from '../../../img/upload.png';

export default function ImageUploader(props) {
    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);
    const maxImages = 3;
    const { t, i18n } = useTranslation();
    const handleFileChange = (event) => {
        if (!event.target.files) return;

        const newFiles = Array.from(event.target.files);

        // Check for maximum image limit
        if (images.length + newFiles.length > maxImages) {
            alert(`You can only upload up to ${maxImages} images.`);
            return;
        }

        // Validate file size (max 100MB per file)
        const validFiles = newFiles.filter((file) => {
            if (file.size > 100 * 1024 * 1024) {
                alert(`${file.name} exceeds the size limit of 100MB.`);
                return false;
            }
            return true;
        });

        // Add valid files to state
        const updatedImages = [...images, ...validFiles].slice(0, maxImages);
        setImages(updatedImages);
        props.imageChanged([...updatedImages])
        // Generate previews for new valid files
        const updatedPreviews = updatedImages.map((file) => URL.createObjectURL(file));
        setPreviews(updatedPreviews);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...images];
        const updatedPreviews = [...previews];
        updatedImages.splice(index, 1);
        updatedPreviews.splice(index, 1);
        setImages(updatedImages);
        props.imageChanged(updatedImages)
        setPreviews(updatedPreviews);
    };

    return (
        <Fragment>
            <label style={styles.uploadButton}>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    style={styles.hiddenInput}
                    onChange={handleFileChange}
                />
                <img src={uploadIcon} alt="uploadPic" style={{ height: "24px", width: "24px", verticalAlign: "sub", marginRight: "12px" }} />
                <span style={{ fontFamily: "Open Sans", fontWeight: "400", fontSize: "20.3px" }}>
                    {t('form.uploadPicButton')}
                </span>
            </label>

            <div style={styles.previewContainer}>
                {previews.map((src, index) => (
                    <div key={index} style={styles.previewWrapper}>
                        <img src={src} alt={`Preview ${index}`} style={styles.previewImage} />
                        <button
                            type="button"
                            onClick={() => handleRemoveImage(index)}
                            style={styles.removeButton}
                        >
                            ×
                        </button>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}

const styles = {
    uploadButton: {
        height: "52px",
        width: "246px",
        margin: '0 auto',
        border: "1px solid black",
        borderRadius: "10px",
        background: "#FBA200",
        color: "black",
        fontSize: "20.3px",
        fontWeight: "400",
        alignContent: "center",
        textAlign: "center",
        cursor: "pointer",
        display: "inline-block",
        transition: "0.3s all ease-out",
        boxShadow: "0px 2.033px 2.033px 0px rgba(0, 0, 0, 0.10), 0px 4.065px 4.065px 0px rgba(0, 0, 0, 0.20)"
    },
    hiddenInput: {
        display: 'none',
    },
    previewContainer: {
        marginTop: "33px",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '1rem',
    },
    previewWrapper: {
        position: 'relative',
        width: '80px',
        height: '80px',
    },
    previewImage: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        objectFit: 'cover',
        border: "1px solid black"
    },
    removeButton: {
        position: 'absolute',
        top: '3px',
        right: '1px',
        margin:0,
        backgroundColor: '#767676',
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        fontSize: '18px',
        padding:"0",
        fontWeight: 'bold',
        cursor: 'pointer',
        lineHeight: '20px',
        textAlign: 'center',
        justifyContent: "center"
    },
};
