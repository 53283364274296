import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask'
import '@fortawesome/fontawesome-free/css/all.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import './GetOffer.css';
import './Widescreen.css';
import '../BookTowTruck/BookTowtruck.css';
import '../../components/seller/seller-footer.css';
import '../../utils/animations.css';
import '../../utils/useApplyClassOnView.css';

// Seller componenets
import SellerLayout from "../../components/seller/SellerLayout";
import BookTowtruck from '../BookTowTruck/BookTowtruck';
import VehicleProfileHash from '../VehicleProfileHash/VehicleProfileHash';


import { LocationProvider } from '../../contexts/LocationContext';

import useScrollToRef from '../../utils/useScrollToRef';
import useShakeAnimation from '../../utils/useShakeAnimation';
// import useApplyClassOnView from "../../utils/useApplyClassOnView";
import CityCarousel from "../../components/shared/cityCarousel";



import logo from '../../img/finalLogoTransparent1208.png';
import greyCursor from '../../img/greyCursor128.png';
import step2 from '../../img/step2.png';
import step3 from '../../img/step3.png';
import step4 from '../../img/step4.png';
import step5 from '../../img/step5.png';
import step6 from '../../img/step6.png';
import step7 from '../../img/step7.png';
import step8 from '../../img/step8.png';
import accept from '../../img/accept128.png';
import catalyticConverter from '../../img/catalyticConverter128.png';
import telephone from '../../img/phone-call128.png';
import name from '../../img/name128.png';
import calendar from '../../img/calendar128.png';
import googleMaps from '../../img/googleMaps128.png';
import mileage from '../../img/speedometer128.png';
import clock from '../../img/clock128.png';
import location from '../../img/location128.png';
import registration from '../../img/registration128.png';
import collision from '../../img/collision128.png';
import accident from '../../img/accident.png';
import flood from '../../img/floodSM.png';
import carfire from '../../img/fireSM.png';
import carkeys from '../../img/keySM.png';
import missingparts from '../../img/missingpartsSM.png';
import automatic from '../../img/automaticSM.png';
import manual from '../../img/manualSM.png';
import saaq from '../../img/saaq.png';
import mto from '../../img/Mto_ontario_logo.png';
import sgi from '../../img/sgiSaskatchewan.png'
import steel from '../../img/steelSmall.png';
import alloy from '../../img/alloySmall.png';
import rims from '../../img/rimsSmall.png';
import flatTire from '../../img/flatTireSmall.png';
import gear from '../../img/gearSmall.png';
import cleancardoor from '../../img/cleancardoorSmall.png';
import airbagIcon from '../../img/airbag.png';
import damageCamera from '../../img/damageCamera.png';
import brokencar from '../../img/brokencarSmall.png';
import ama from '../../img/ama.png';
import icbcSmall from '../../img/icbcSmall.png';
import circledArrow from '../../img/arrow128.png';
import checkEngine from '../../img/engineWarning.png'
import checkTransmission from '../../img/transmissionWarning.png'
import gearCheck from '../../img/fanbeltcar.png'
import { addresses } from '../../config/api/addresses';
import useFetch from '../../shared/hooks/useFetch';
import CookieConsentModal from '../../components/shared/coockiePrompt';
import useDebounce from '../../shared/hooks/useDebounce';
import PrivacyAndPolicy from '../PrivacyAndPolicy/PrivacyAndPolicy';
import Blog from '../Blog';
import BlogPost from '../BlogPost';
import ImageUploader from '../../components/get-offer/upload-pic/UploadPic';
import config from '../../config';

const GetOffer = () => {

    // Make, model, year
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    // Mileage defs
    const [selectedMileage, setSelectedMileage] = useState(null);
    const [inputMileage, setInputMileage] = useState(null);
    const [isMileageUnknown, setIsMileageUnknown] = useState(false);

    // Postal code defs
    const [postalCode, setPostalCode] = useState("");
    const [selectedOwnership, setSelectedOwnership] = useState('');
    const [selectedTireCondition, setSelectedTireCondition] = useState(null);
    const [selectedWorkingCondition, setSelectedWorkingCondition] = useState('');
    const [transmissionType, setTransmissionType] = useState('');
    const [alloyOrSteel, setAlloyOrSteel] = useState(null);
    const [isWheelQuestionSkipped, setIsWheelQuestionSkipped] = useState(false);
    const [selectedMissingParts, setSelectedMissingParts] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [offeredPrice, setOfferedPrice] = useState(null);
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);
    const [hasDamage, setHasDamage] = useState(null);
    const [hasFloodOrFire, setHasFloodOrFire] = useState(null);
    const [doesRun, setDoesRun] = useState(null);
    const [airbag, setAirbag] = useState(null);
    // Button feedback for the Calc offer button to show feedback
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    // Phone number validation defs
    const [phoneNumber, setPhoneNumber] = useState('');
    // After offer is accepted, setting up time adn addy
    const [isOfferAccepted, setIsOfferAccepted] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState({ from: '', to: '', dayTime: '' });
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isPriceSuggested, setIsPriceSuggested] = useState(false);
    const [desiredPrice, setDesiredPrice] = useState('');
    const [unableToUpload, setUnableToUpload] = useState(false);
    // Translation using the i18next lib
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');
    // Loading offer bullet point list variables
    const [showItem1, setShowItem1] = useState(false);
    const [showItem2, setShowItem2] = useState(false);
    const [showItem3, setShowItem3] = useState(false);
    const [showItem4, setShowItem4] = useState(false);
    const [showDesiredPrice, setShowDesiredPrice] = useState(false);
    const [showCommentsSection, setShowCommentsSection] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [tellMeMore, setTellMeMore] = useState(false);
    const [, setIsDesktop] = useState(window.innerWidth > 1024);

    // Scroll into view Ref declarations
    const makeRef = useRef(null);
    const modelRef = useRef(null);
    const yearRef = useRef(null);
    const mileageRef = useRef(null);
    const postalCodeRef = useRef(null);
    const selectedOwnershipRef = useRef(null);
    const selectedTireConditionRef = useRef(null);
    const selectedWorkingConditionRef = useRef(null);
    const transmissionRef = useRef(null);
    const wheelTypeRef = useRef(null);
    const hasFloodFireRef = useRef(null);
    const hasDamageRef = useRef(null);
    const doesRunRef = useRef(null);
    const selectedMissingPartsRef = useRef(null);
    const acceptOfferRef = useRef(null);
    const namePhoneContainerRef = useRef(null);
    const calculateOfferRef = useRef(null);
    const offerAcceptedRef = useRef(null);
    const offerSuggestedRef = useRef(null);
    const pickupDateRef = useRef(null);
    const pickupTimeRef = useRef(null);
    const stepsContainerRef = useRef(null);
    const rejectResponseBoxRef = useRef(null);
    const whatHappensNextRef = useRef(null);
    const phoneVerificationRef = useRef(null);
    const airbagRef = useRef(null);
    const startBoxRef = useRef(null);
    const tellMeMoreRef = useRef(null);
    const uploadPicRef = useRef(null);
    const uploadImages = useRef([]);
    const [get, set] = useState([]);

    const calculateValues = useRef(null)
    const [clientProvince, setClientProvince] = useState('');
    const [lastPhoneNumberSent, setLastPhoneNumberSent] = useState(null)

    // Animation stuff
    const [startBoxShouldJiggle, setStartBoxShouldJiggle] = useState(false);
    const isShaking = useShakeAnimation(startBoxRef, startBoxShouldJiggle);

    const jiggleMakeBox = () => {
        setStartBoxShouldJiggle(false); // Reset the state to false
        setTimeout(() => {
            setStartBoxShouldJiggle(true); // Then set it to true to trigger the animation
        }, 10); // A short delay ensures the state change is recognized
    };

    const { data: carData, isLoading: loadingCarData } = useFetch('get', {
        autoGetUrl: addresses.getCarData,
        mapper(result) {
            return result?.data
        }
    })

    const { data: carModels, fetch: fetchCarModel, isLoading: loadingCarModels } = useFetch('get', {
        mapper(result) { return result?.data }
    })

    const { data: carModelYears, fetch: fetchCarModelYears, isLoading: loadingCarModelYears } = useFetch('get', {
        mapper(result) { return result?.data }
    })

    const { data: taskId, fetch: sendPostalCode, isLoading: loadingPostalCodeSending } = useFetch('post', {
        mapper(result) { return result?.task_id },
        customErrorHandler: (err) => { toast.error(err.message) }
    })

    const { data: message, fetch: getDistance, isLoading: loadingDistance } = useFetch('get', {
        mapper(result) { return result?.message },
        customErrorHandler: (err) => { toast.error(err.message) }
    })

    const { data: vehicleProfile, fetch: sendVehicleProfile, isLoading: loadingVehicleProfile } = useFetch('post')
    const { data: patchVehicleProfile, fetch: sendPatchVehicleProfile, isLoading: loadingPatchVehicleProfile } = useFetch('patch')

    const { data: sellerInfo, fetch: sendSellerInfo, isLoading: loadingSellerInfo } = useFetch('post')
    const { data: patchSellerInfo, fetch: sendPatchSellerInfo, isLoading: loadingPatchSellerInfo } = useFetch('patch')

    const { data: addressSuggestions, fetch: fetchAddressSuggestions, isLoading: loadingAddressSuggestions } = useFetch('get', {
        mapper(result) { return result?.predictions }
    })

    const { data: highestOffer, fetch: fetchHighestOffer, isLoading: loadingHighestOffer } = useFetch('get')

    const { data: postalCodeDetails, fetch: getDetailOfPostalCode } = useFetch('get')

    const { fetch: sendAcceptOffer, isLoading: loadingAcceptOffer } = useFetch('post')

    const { fetch: sendRejectOffer, isLoading: loadingRejectOffer } = useFetch('post')

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth > 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const calculateOfferedPrice = async () => {
        if (loadingPatchVehicleProfile || loadingAcceptOffer || loadingAddressSuggestions || loadingCarData || loadingSellerInfo || loadingRejectOffer || loadingPatchSellerInfo || loadingVehicleProfile) return
        let vehiclSpecs
        if (selectedOwnership === 'no_title') {
            toast.warn("Make sure you have the vehicle's title! Unfortunately, we cannot buy your car from you without its title.");
            return;
        } else if (isPhoneNumberFullyFilled(phoneNumber) === false) {
            namePhoneContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.warn(t('form.phoneNumberNotFilled'));
            return
        } else if (isValidCanadianAreaCode(phoneNumber) === false) {
            namePhoneContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.warn(t('form.phoneNumberNotValid'));
            return
        } else {
            vehiclSpecs = carModelYears?.find(x => x.year == selectedYear)?.id
            if (!vehiclSpecs) {
                toast.warn("Make sure you have selected make model year correctly");
                return
            }
        }
        const reqData = {
            "task_id": taskId,
            "vehicle_specs": vehiclSpecs,
            "mileage": selectedMileage,
            "airbag_deployed": airbag,
            "ownership": selectedOwnership,
            "tire_condition": selectedTireCondition,
            "wheel_type": alloyOrSteel,
            "working_condition": selectedWorkingCondition,
            "transmission_type": transmissionType,
            "running_condition": doesRun,
            "catastrophic_damage": hasFloodOrFire,
            "body_condition": hasDamage,
            "nothing_missing": selectedMissingParts.indexOf('nothingMissing') > -1,
            "catalyzer_missing": selectedMissingParts.indexOf('catalyserMissing') > -1,
            "other_parts_missing": selectedMissingParts.indexOf('otherPartsMissing') > -1,
        }

        const customerProvinece = {
            quebec: "SAAQ",
            ontario: "MTO",
            "british columbia": "ICBC",
            alberta: "AMA",
            manitoba: "MPI",
            saskatchewan: "SGI",
        }

        setOfferedPrice(null);
        const postalDetail = await getDetailOfPostalCode(`${addresses.getDetailPostalCode}/${postalCode.replace(" ", "")}`, {})
        if (postalDetail) {
            if (customerProvinece[postalDetail.province]) {
                setClientProvince(customerProvinece[postalDetail.province])
            }
        }
        let response;
        if (vehicleProfile && vehiclSpecs && vehiclSpecs === calculateValues?.current?.['vehiclSpecs']) {
            response = await sendPatchVehicleProfile(`${addresses.sendVehicleProfile}${vehicleProfile.id}/`, { ...reqData })
        } else {
            response = await sendVehicleProfile(addresses.sendVehicleProfile, { ...reqData })
        }

        calculateValues.current = {}
        calculateValues.current['vehiclSpecs'] = vehiclSpecs

        if (response) {
            let sellerInfoResponse
            const highestOffer = await fetchHighestOffer(`${addresses.getHighestOffer}/${response.id}/highest-offer`)
            setOfferedPrice(highestOffer?.offered_price)
            if (`+1${phoneNumber}` !== lastPhoneNumberSent) {
                sellerInfoResponse = await sendSellerInfo(addresses.sendSellerInfo, {
                    "full_name": firstName,
                    "phone_number": `+1${phoneNumber}`,
                    "language": selectedLanguage === "en" ? 'English' : 'French',
                    "vehicle_profile_id": response.id
                })
                if (sellerInfoResponse) {
                    setLastPhoneNumberSent(`+1${phoneNumber}`)
                }
            } else {
                sellerInfoResponse = await sendPatchSellerInfo(`${addresses.sendSellerInfo}${sellerInfo?.id}/`, {
                    "full_name": firstName,
                    "phone_number": `+1${phoneNumber}`,
                    "language": selectedLanguage === "en" ? 'English' : 'French',
                    "vehicle_profile_id": response.id
                })
                if (sellerInfoResponse) {
                    setLastPhoneNumberSent(`+1${phoneNumber}`)
                }
            }
            if (uploadImages.current.length > 0) {
                const formData = new FormData();
                uploadImages?.current?.forEach((file) => {
                    formData.append('file', file)
                });
                formData.append('seller_id', sellerInfoResponse?.id);
                formData.append('access', 'public');
                formData.append('thumbnail', '1');
                try {
                    const imagesRequest = await fetch(`${config.apiUrl}${addresses.fileUpload}/`, { method: "POST", body: formData })
                    if (imagesRequest.ok) {
                        const responseImages = await imagesRequest.json()
                        uploadImages.current = []
                        reqData['images'] = responseImages?.result?.ids ?? [responseImages?.result?.id]
                        await sendPatchVehicleProfile(`${addresses.sendVehicleProfile}${response.id}/`, reqData)
                    } else {
                        toast.warn('Failed to upload images.');
                    }
                } catch (error) {
                    toast.warn('Error uploading images.')
                }

            }

        }

    };

    const handleMakeChange = async (event) => {
        setSelectedMake(event.target.value);
        setSelectedModel('')
        setSelectedYear('')
        setTimeout(() => {
            modelRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
        }, 100);
        await fetchCarModel(`${addresses.getCarModel}?make=${event.target.value}`)
    }

    const handleModelChange = async (event) => {
        setSelectedModel(event.target.value);
        setSelectedYear('')
        setTimeout(() => {
            yearRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
        }, 100)
        await fetchCarModelYears(`${addresses.getCarModelYears}?make=${selectedMake}&model=${event.target.value}`)
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        setTimeout(() => {
            mileageRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
        }, 100)
    };

    const handleMileageChange = (event) => {
        const inputValue = event.target.value;
        // Check if the input value only contains digits (0-9)
        const isValidInput = /^[0-9]+$/.test(inputValue);

        if (isValidInput) {
            // Parse the input value as a float
            const parsedValue = parseFloat(inputValue);

            // Check if the parsed value is a number
            if (!isNaN(parsedValue)) {
                // Format the input mileage value as thousands
                const formattedInputValue = parsedValue.toLocaleString();

                // Set the selectedMileage to the parsed value (in single units)
                setSelectedMileage(parsedValue * 1000);

                // Set the inputMileage value with formatting (in thousands)
                setInputMileage(formattedInputValue);

                setIsMileageUnknown(false);
                if (inputValue.length === 3) {
                    setTimeout(() => {
                        postalCodeRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            } else {
                // Handle invalid input by setting selectedMileage to null
                setSelectedMileage(null);
                // Keep the input value as entered (can be non-numeric)
                setInputMileage(inputValue);
                setIsMileageUnknown(true);
            }
        } else {
            // Handle invalid input (non-numeric characters)
            setSelectedMileage(null);
            setInputMileage(inputValue);
            setIsMileageUnknown(true);
        }
    };

    const changeWheel = (value) => {
        setAlloyOrSteel(value);
        if (value === 'steel' || value === 'alloy') {
            setIsWheelQuestionSkipped(false);
        } else {
            setIsWheelQuestionSkipped(true);
        }
        setTimeout(() => {
            selectedWorkingConditionRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
        }, 100)
    }

    const handleOwnershipChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOwnership(selectedValue);
        if (selectedValue === 'no_title') {
            toast.warn(t('form.titleUnavailable'));
            return
        }
        setTimeout(() => {
            selectedTireConditionRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
        }, 100)
    }

    const handleTireConditionChange = (event) => {
        const clickedButtonValue = event.target.value;
        setSelectedTireCondition(clickedButtonValue);
        if (clickedButtonValue !== 'tires_missing') {
            setTimeout(() => {
                wheelTypeRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }, 100)
        } else {
            setTimeout(() => {
                selectedWorkingConditionRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }, 100)
        }
    };

    const handleWorkingConditionChange = (event) => {
        setSelectedWorkingCondition(event.target.value);
        setTimeout(() => {
            transmissionRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
        }, 100);
    };

    const changeTransmission = (value) => {
        setTransmissionType(value)
        setTimeout(() => {
            doesRunRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
        }, 100);
    };

    const changeDoesRun = (value) => {
        setDoesRun(value)
        setTimeout(() => {
            hasDamageRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
        }, 100);
    };

    const changeFloodFire = (floodFire) => {
        setHasFloodOrFire(floodFire)
        setTimeout(() => {
            if (floodFire === 'has_accident') {
                airbagRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            } else {
                setAirbag(null)
                selectedMissingPartsRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }
        }, 100);
    };

    const handleChangeDamage = (bodyDamage) => {
        setHasDamage(bodyDamage)
        if (bodyDamage === 'no_damage') {
            setHasFloodOrFire(null)
            setAirbag(null)
            setUnableToUpload(false)
        }
        setTimeout(() => {
            if (bodyDamage === 'body_damage') {
                hasFloodFireRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            } else {

                selectedMissingPartsRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }
        }, 100);
    };
    const handleChangeAirbag = (airbag) => {
        setAirbag(airbag)
        setTimeout(() => {
            if (airbag === true && !unableToUpload) {
                uploadPicRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            } else if (hasDamage === 'body_damage' && !unableToUpload && uploadImages.current.length === 0) {
                uploadPicRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            } else {
                selectedMissingPartsRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }
        }, 100);
    };

    const onUploadImage = (imagesFile) => {
        if (uploadImages.current) {
            uploadImages.current = [...imagesFile]
            set(imagesFile)
        }
        if (imagesFile?.length >= 3) {
            setTimeout(() => {
                selectedMissingPartsRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }, 100);
        }
    }

    const handleMissingPartsChange = (event) => {
        const clickedValue = event.currentTarget.value;
        if (clickedValue === "nothingMissing") {
            setSelectedMissingParts(["nothingMissing"]);
        } else {
            if (selectedMissingParts.includes(clickedValue)) {
                // If the clicked value is already in the selected parts, remove it
                setSelectedMissingParts((prevMissingParts) => prevMissingParts.filter(part => (part !== clickedValue && part !== 'nothingMissing')));
            } else {
                // If the clicked value is not in the selected parts, add it
                setSelectedMissingParts((prevMissingParts) => [...prevMissingParts.filter(part => part !== 'nothingMissing'), clickedValue]);
            }
        }
        setTimeout(() => {
            namePhoneContainerRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" })
        }, 100);
    };

    // Name handler
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    // 2 phone number verification functions
    const validatePhoneNumber = (phone) => {
        return isPhoneNumberFullyFilled(phone) && isValidCanadianAreaCode(phone);
    };

    const isPhoneNumberFullyFilled = (phone) => {
        // Remove non-numeric characters for length check
        const numericPhoneNumber = phone.replace(/\D/g, '');
        // Check if it's fully filled without placeholder characters
        return !phone.includes("_") && numericPhoneNumber && numericPhoneNumber.length === 10;
    };

    const isValidCanadianAreaCode = (phone) => {
        const canadianAreaCodes = [
            204, 226, 236, 249, 250, 289, 306, 343, 365, 403, 416, 418, 431, 437, 438, 450,
            506, 514, 519, 579, 581, 587, 604, 613, 639, 647, 705, 709, 778, 780, 782,
            807, 819, 825, 867, 873, 902, 905, 818
        ].map(code => code.toString());

        // Extract the numeric part of the phone number
        const numericPhoneNumber = phone.replace(/\D/g, '');
        // Extract the area code
        const areaCode = numericPhoneNumber.slice(0, 3);

        // Check if the area code is valid
        return canadianAreaCodes.includes(areaCode);
    };

    // Event handle using Input Mask
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleSubmitAccept = async () => {
        // Turn on the loading screen
        setIsLoadingScreen(true);

        // Function to format month and day
        function newFormatDate(date) {
            const options = { month: 'long', day: 'numeric', timeZone: 'UTC' };
            let formattedDate = date.toLocaleDateString('en-US', options);

            // Extract the month and day components separately
            const [month, day] = formattedDate.split(' ');

            // Adding ordinal suffix to day
            const dayNum = parseInt(day, 10); // Convert string to number
            let suffix = 'th';
            if (dayNum % 10 === 1 && dayNum !== 11) suffix = 'st';
            else if (dayNum % 10 === 2 && dayNum !== 12) suffix = 'nd';
            else if (dayNum % 10 === 3 && dayNum !== 13) suffix = 'rd';

            // Return formatted date with comma and ordinal suffix
            return `${month}, ${dayNum}${suffix}`;
        }

        // Function to format time in AM/PM format
        function formatTime(date) {
            // const options = { hour: 'numeric', hour12: true, timeZone: 'UTC' };
            const regex = /(\d+:\d+):\d+\s*([AP]M)/i;
            // Replace function to format it into a simpler 'h:mma' format
            return date.toLocaleTimeString('en-US').replace(regex, (match, hourMinute, amPm) => {
                return `${hourMinute}${amPm.toLowerCase()}`;
            });
        }

        const convertToISOString = (date, time) => {
            const dateTimeString = `${date}T${time}`;
            const dateObject = new Date(dateTimeString);
            return dateObject.toISOString();
        }

        const timeFrom = convertToISOString(selectedDate, selectedTime.from)
        const timeTo = convertToISOString(selectedDate, selectedTime.to)
        const acceptOfferRes = await sendAcceptOffer(addresses.acceptOffer, {

            "buyer_offer_id": highestOffer.id,
            "appointment_details": {
                "address": {
                    "address_str": address,
                    "postal_code": postalCode
                },
                "pickup_time_from": timeFrom,
                "pickup_time_to": timeTo,
                "str_pickup_date": newFormatDate(new Date(timeFrom)),
                "str_pickup_time": `${formatTime(new Date(timeFrom))} and ${formatTime(new Date(timeTo))}`,
            }

        })

        if (acceptOfferRes) {
            toast(t('form.pickupConfirmation', { sellerPhoneNumber: phoneNumber }));
            resetForm();
            setIsFormSubmitted(true);
            setIsLoadingScreen(false);
            setTimeout(() => {
                if (whatHappensNextRef.current) {
                    whatHappensNextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 400);

        }
    };

    const handleSubmitReject = async () => {
        setIsLoadingScreen(true);
        const rejectRes = await sendRejectOffer(addresses.rejectOffer, {
            "vehicle_profile_id": vehicleProfile.id,
            "desired_offer": desiredPrice
        })
        if (rejectRes) {
            toast(t('form.offerForwarded', { sellerPhoneNumber: phoneNumber }));
            resetForm();
            setIsFormSubmitted(true);
            setIsLoadingScreen(false);
            setTimeout(() => {
                if (whatHappensNextRef.current) {
                    whatHappensNextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 400);
        }

    };

    // Accept/ Reject offer functions
    const handleAcceptOffer = async () => {
        // setUserConsent(null);
        setIsOfferAccepted(true);
        setIsPriceSuggested(false);

    }

    const handleSuggestPrice = () => {
        // setUserConsent(null);
        if (isPhoneNumberFullyFilled(phoneNumber) === false) {
            // Scroll to the phone input container
            namePhoneContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            // Show the alert
            toast.warn(t('form.phoneNumberNotFilled'));
        }
        else if (isValidCanadianAreaCode(phoneNumber) === false) {
            // Scroll to the phone input container
            namePhoneContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            // Show the alert
            toast.warn(t('form.phoneNumberNotValid'));
        } else {
            setIsPriceSuggested(true);
            setIsOfferAccepted(false);
        }
    };

    const handleFullRejectOffer = async () => {
        await sendRejectOffer(addresses.rejectOffer, {
            "vehicle_profile_id": vehicleProfile.id,
            "desired_offer": desiredPrice
        })
        toast(t('form.fullRejectAlert'));
    }

    const onClickUnableToUpload = () => {
        setUnableToUpload(prev => {
            if (!prev) {
                setTimeout(() => {
                    selectedMissingPartsRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" })
                }, 100);
            } else {
                setSelectedMissingParts([])
                uploadPicRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" })
            }
            return !prev
        })
    }

    const handlePriceChange = (e) => {
        const value = e.target.value;

        // Remove non-numeric characters and keep only numbers
        const numericValue = value.replace(/[^0-9]/g, '');

        // Update state with the numeric value
        setDesiredPrice(numericValue);
    }

    // Handler for address input and autofill suggestion
    const handleAddressChange = async (event) => {
        const inputValue = event.target.value;
        setAddress(inputValue); // Update your address state

        if (inputValue.length > 0) { // Check if the input value is not empty
            const predictions = await fetchAddressSuggestions(`${addresses.addressAutoComplete}?input=${encodeURIComponent(inputValue)}`)
            if (predictions) {
                setSuggestions(predictions); // Assuming the API returns predictions
            }
        } else {
            setSuggestions([]); // Clear suggestions if the input is empty
        }
    };

    // Handler for when a suggestion is clicked
    const handleSuggestionClick = (suggestion) => {
        setAddress(suggestion.description); // Update the input field with the suggestion
        setSuggestions([]); // Clear suggestions after selection
    };

    const emojiMapping = {
        '{moneyEmoji}': String.fromCodePoint(0x1F4B0),
        '{recyclingEmoji}': String.fromCodePoint(0x267B),
        '{truckEmoji}': String.fromCodePoint(0x1F69A),
        '{checkMarkEmoji}': String.fromCodePoint(0x2705),
        '{computerEmoji}': String.fromCodePoint(0x1F4BB),
        '{magnifyingGlassEmoji}': String.fromCodePoint(0x1F50D),
    };

    function replaceEmojis(str, emojiMapping) {
        let result = str;

        Object.keys(emojiMapping).forEach((key) => {
            const emoji = emojiMapping[key];
            const regex = new RegExp(key, 'g');
            result = result.replace(regex, emoji);
        });

        return result;
    }

    // Scroll down (event driven)
    const scrollToStartBox = () => {
        setTimeout(() => {
            startBoxRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
        }, 100);
    };

    // Event handler for postal code input changes
    const handlePostalCodeChange = async (e) => {
        let updatedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        if (updatedValue.length > 3) {
            updatedValue = updatedValue.slice(0, 3) + ' ' + updatedValue.slice(3);
        }
        setPostalCode(updatedValue);
        if (updatedValue.length === 7) {
            setTimeout(() => {
                selectedOwnershipRef?.current?.scrollIntoView?.({ behavior: "smooth", block: "center" })
            }, 100);
            const task_id = await sendPostalCode(addresses.sendPostalCode, { postal_code: updatedValue })
            if (task_id) {
                getDistance(`${addresses.getDistance}/${task_id}?language=${selectedLanguage === "en" ? 'English' : 'French'}`)
            }
        }
    };

    // Use effect to set offered price to null if they change an input value
    useEffect(() => {
        if (offeredPrice !== null) {
            setOfferedPrice(null);
            setIsOfferAccepted(false);
            setIsPriceSuggested(false);
        }
    }, [
        selectedMake,
        selectedModel,
        selectedYear,
        selectedMileage,
        selectedMissingParts,
        selectedOwnership,
        selectedWorkingCondition,
        selectedTireCondition,
        transmissionType,
        hasFloodOrFire,
        hasDamage,
        postalCode
    ]);


    useScrollToRef(validatePhoneNumber(phoneNumber), calculateOfferRef);
    useScrollToRef(loadingHighestOffer || offeredPrice !== null, acceptOfferRef);
    useScrollToRef(isOfferAccepted && !validatePhoneNumber(phoneNumber), namePhoneContainerRef);
    useScrollToRef(isOfferAccepted && validatePhoneNumber(phoneNumber), offerAcceptedRef);
    useScrollToRef(isPriceSuggested && validatePhoneNumber(phoneNumber), offerSuggestedRef);
    useScrollToRef(isPriceSuggested && !validatePhoneNumber(phoneNumber), namePhoneContainerRef);
    useScrollToRef(phoneNumber === '' && (isOfferAccepted || isPriceSuggested), namePhoneContainerRef);

    // Fix page not loading at the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToStepsContainer = () => {
        stepsContainerRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
    };

    useScrollToRef(showDesiredPrice || showCommentsSection, rejectResponseBoxRef);
    useScrollToRef(selectedDate, pickupDateRef);
    useScrollToRef(selectedTime, pickupTimeRef);


    // Function to compute the earliest pickup day (today or tomorrow)
    const now = new Date();
    const currentHour = now.getHours();

    const getEarliestPickupDate = () => {

        // If car is 2011 or younger
        if (parseInt(selectedYear) >= 2011) {
            if (currentHour >= 0 && currentHour < 20) {
                return 'today';
            } else if (currentHour >= 20 && currentHour < 24) {
                return 'tomorrow';
            }
        } else { // For cars older than 2011
            if (currentHour >= 0 && currentHour < 16) {
                return 'today';
            } else {
                return 'tomorrow';
            }
        }
    };

    const earliestPickupDate = getEarliestPickupDate();
    const isToday = earliestPickupDate === 'today';
    const formattedDate = isToday ? t('form.today') : t('form.tomorrow');

    // Date picker modifications
    const [showDatePicker, setShowDatePicker] = useState(false);

    function getDateString(daysFromToday) {
        const date = new Date();
        date.setDate(date.getDate() + daysFromToday);

        // Adjust for the timezone offset
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.getTime() - userTimezoneOffset);

        return adjustedDate.toISOString().split('T')[0];
    }

    // Show the offer-notes sequentially
    useEffect(() => {
        if (offeredPrice !== null) {
            setShowItem1(false);
            setShowItem2(false);
            setShowItem3(false);
            setShowItem4(false);

            setTimeout(() => setShowItem1(true), 300); // Delay for item 1
            setTimeout(() => setShowItem2(true), 600); // Delay for item 2
            setTimeout(() => setShowItem3(true), 900); // Delay for item 3
            setTimeout(() => setShowItem4(true), 1200); // Delay for item 4
        }
    }, [offeredPrice]);

    // remove the items if the price is null
    useEffect(() => {
        if (offeredPrice === null) {
            setShowItem1(false);
            setShowItem2(false);
            setShowItem3(false);
            setShowItem4(false);
        }
    }, [offeredPrice]);

    const resetForm = () => {
        setIsOfferAccepted(false);
        setIsPriceSuggested(false);
        // setIsLoading(false);
        setDesiredPrice("$");
        setShowCommentsSection(false);
        setShowDesiredPrice(false);
        setDesiredPrice('');
        setOfferedPrice(null);
    }

    return (
        <>
            <ToastContainer />
            {/* <CookieConsentModal /> */}
            <Router>
                <LocationProvider>
                    <Routes>
                        {/* Base url redirect to calculate offer page */}
                        <Route path="/" element={<Navigate to="/sell/calculate-offer" />} />
                        {/* Seller Routes */}
                        <Route path="/sell" element={<SellerLayout
                            stepsContainerRef={stepsContainerRef}
                            startBoxRef={startBoxRef}
                            scrollToHowItWorks={scrollToStepsContainer}
                            scrollToStartBox={scrollToStartBox}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                            clientPhoneNumber={''}
                            clientCallingHours={''}
                        />}>
                            {/* <Route path="about-us" element={<AboutUsWrapper
                            setShouldScrollToStartBox={setShouldScrollToStartBox}
                            shouldScrollToStartBox={shouldScrollToStartBox}
                            selectedLanguage={selectedLanguage}
                        />} /> */}
                            <Route path="calculate-offer" element={
                                <div className="parent-div">

                                    {/* <div className={`instruction`}>
                                    <button
                                        ref={orangeButton1Ref}
                                        onClick={scrollToStartBox}
                                        className={`instruction-button orange-button ${orangeButtonClass}`}
                                    >
                                        {t('intro.instructions')}
                                        <img src={greyCursor} alt="Grey Cursor" className="cursor1" />
                                    </button>
                                    <button
                                        ref={blueButton1Ref}
                                        onClick={() => setShowHowItWorks(true)}
                                        className={`instruction2-button blue-button ${isBlueButtonVisible ? 'reveal-animation2' : ''}`}
                                    >
                                        {t('intro.instructions2')}
                                        <img src={greyCursor} alt="Grey Cursor" className="cursor2" />
                                    </button>
                                </div> */}

                                    <CityCarousel />
                                    <div className='quick-description-responsive'>
                                        <div className="quick-description-container">

                                            {/* <div ref={quickDesc1Ref} className={`quick-description1 ${isQuickDesc1Visible ? 'reveal-animation2' : ''}`}> */}
                                            <div className={`quick-description1`}>
                                                {t('header.titleStatement2')}
                                            </div>
                                            {/* <div ref={quickDesc1Ref} className={`quick-description3  ${isQuickDesc1Visible ? 'reveal-animation2' : ''}`}> */}
                                            <div className={`quick-description3`}>
                                                {t('header.subTitleStatement')}
                                            </div>
                                        </div>

                                        {/* <div ref={logoRef} className={`logo-container ${isLogoVisible ? 'reveal-animation1' : ''}`}> */}
                                        <div className={`logo-container`}>
                                            <img src={logo} alt={t('form.title')} className="logo-image" />
                                        </div>
                                    </div>

                                    <div className="quick-description-container">


                                        {/* <div ref={quickDesc2Ref} className={`quick-description2 ${isQuickDesc2Visible ? 'reveal-animation1' : ''}`}> */}
                                        <div className={`quick-description2`}>
                                            {t('header.subTitleStatement')}
                                        </div>
                                        <button
                                            // ref={orangeButton1Ref}
                                            onClick={scrollToStartBox}
                                            // className={`instruction-button orange-button ${orangeButtonClass}`}
                                            className={`instruction-button orange-button`}
                                        >
                                            {t('intro.instructions')}
                                            <img src={greyCursor} alt="Grey Cursor" className="cursor1" />
                                        </button>
                                        <button
                                            // ref={blueButton1Ref}
                                            onClick={scrollToStepsContainer}
                                            // className={`instruction2-button blue-button ${isBlueButtonVisible ? 'reveal-animation2' : ''}`}
                                            className={`instruction2-button display-md-none blue-button`}
                                        >
                                            {t('intro.instructions2')}
                                            <img src={greyCursor} alt="Grey Cursor" className="cursor2" />
                                        </button>

                                    </div>


                                    {/* {(showHowItWorks || isDesktop) && ( */}

                                    {/* <div ref={stepsContainerRef} className="steps-container reveal-animation3"> */}
                                    <div ref={stepsContainerRef} className="steps-container">

                                        <div className="steps-container-widescreen-modifier">

                                            {/* <div ref={step1Ref} className={`step ${step1Class}`}> */}
                                            <div className={`step`}>
                                                <div className="header-container">
                                                    <div className="step-number">{t('form.steps.header')} 1</div>
                                                    <h2>{t('form.steps.step2.title')}</h2>
                                                </div>
                                                <div className="step-image-container">
                                                    <img src={step2} alt={t('form.steps.step2.title')} className="step2-image" />
                                                </div>
                                                <div className="step-paragraph-container">
                                                    <p>{replaceEmojis(t('form.steps.step2.description'), emojiMapping)}</p>
                                                </div>
                                            </div>

                                            {/* <div ref={step2Ref} className={`step ${step2Class}`}> */}
                                            <div className={`step`}>
                                                <div className="header-container">
                                                    <div className="step-number">{t('form.steps.header')} 2</div>
                                                    <h2>{t('form.steps.step4.title')}</h2>
                                                </div>
                                                <div className="step-image-container">
                                                    <img src={step4} alt={t('form.steps.step4.title')} className="step4-image" />
                                                </div>
                                                <div className="step-paragraph-container">
                                                    <p>{replaceEmojis(t('form.steps.step4.description'), emojiMapping)}</p>
                                                </div>
                                            </div>

                                            {/* <div ref={step3Ref} className={`step ${step3Class}`}> */}
                                            <div className={`step`}>
                                                <div className="header-container">
                                                    <div className="step-number">{t('form.steps.header')} 3</div>
                                                    <h2>{t('form.steps.step8.title')}</h2>
                                                </div>
                                                <div className="step-image-container">
                                                    <img src={step8} alt="scrap car sold" className="step8-image" />
                                                </div>
                                                <div className="step-paragraph-container">
                                                    <p>{replaceEmojis(t('form.steps.step8.description'), emojiMapping)}</p>
                                                </div>
                                            </div>

                                        </div>

                                        {tellMeMore !== true && (
                                            <div className="tell-me-more-button-container">
                                                <button className="tell-me-more-button" onClick={() => setTellMeMore(true)}>
                                                    {t('form.steps.tellMeMore')}
                                                    <img src={circledArrow} alt="Little Arrow" className="circled-arrow-icon" />
                                                </button>
                                            </div>
                                        )}

                                        {tellMeMore === true && (
                                            <>
                                                <div ref={tellMeMoreRef} className="steps-container-widescreen-modifier">
                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 4</div>
                                                        <h2>{t('form.steps.step5.title')}</h2>
                                                        <div>
                                                            <img src={step5} alt="automatic text message" className="step5-image" />
                                                            <p>{replaceEmojis(t('form.steps.step5.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 5</div>
                                                        <h2>{t('form.steps.step6.title')}</h2>
                                                        <div>
                                                            <img src={step6} alt="get a call from a specialist" className="step6-image" />
                                                            <p>{replaceEmojis(t('form.steps.step6.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>

                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 6</div>
                                                        <h2>{t('form.steps.step7.title')}</h2>
                                                        <img src={step7} alt="registration keys and driver's license" className="step7-image" />
                                                        <p>{replaceEmojis(t('form.steps.step7.description'), emojiMapping)}</p>
                                                    </div>

                                                    <div className="step">
                                                        <div className="header-container">
                                                            <div className="step-number">{t('form.steps.header')} 7</div>
                                                            <h2>{t('form.steps.step3.title')}</h2>
                                                        </div>
                                                        <img src={step3} alt={t('form.steps.step3.title')} className="step3-image" />
                                                        <p>{replaceEmojis(t('form.steps.step3.description'), emojiMapping)}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* // )
                                // } */}


                                    <div ref={startBoxRef} className="box">
                                        <button
                                            className="box-title"
                                            onClick={() => {
                                                jiggleMakeBox();
                                                scrollToStartBox();
                                            }}
                                        >
                                            {replaceEmojis(t('form.boxTitle'), emojiMapping)}
                                        </button>

                                        <div ref={startBoxRef} className={`dropdown-question ${isShaking ? "shake" : ""}`} id="carMake">
                                            <label
                                                ref={makeRef}
                                                className='carMakeModelYearsLabel'
                                                htmlFor="carMake">
                                                {t('form.selectCarMake')}
                                                {loadingCarData &&
                                                    <svg className='svgSpinner' viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
                                                        <circle class="spin" cx="400" cy="400" fill="none"
                                                            r="180" stroke-width="39" stroke="#E387FF"
                                                            stroke-dasharray="860 1400"
                                                            stroke-linecap="round" />
                                                    </svg>}
                                            </label>
                                            <select id="carMake" value={selectedMake} onChange={handleMakeChange}>
                                                <option value="">{t('form.makeOption')}</option>
                                                {carData && carData.map((make, index) => (
                                                    <option key={index} value={make}>
                                                        {make}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {selectedMake &&
                                            <div ref={modelRef} className="dropdown-question" id={"carModel"}>
                                                <label htmlFor="carModel" className='carMakeModelYearsLabel'>
                                                    {t('form.selectCarModel')}
                                                    {loadingCarModels &&
                                                        <svg className='svgSpinner' viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
                                                            <circle class="spin" cx="400" cy="400" fill="none"
                                                                r="180" stroke-width="39" stroke="#E387FF"
                                                                stroke-dasharray="860 1400"
                                                                stroke-linecap="round" />
                                                        </svg>}
                                                </label>
                                                <select id="carModel" value={selectedModel} onChange={handleModelChange}>
                                                    <option value="">{t('form.modelOption')}</option>
                                                    {carModels && carModels.map((model, index) => (
                                                        <option key={index} value={model}>
                                                            {model}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>}

                                        {selectedModel &&
                                            <div ref={yearRef} className="dropdown-question" id={"carYear"}>
                                                <label htmlFor="carYear" className='carMakeModelYearsLabel'>
                                                    {t('form.selectCarYear')}
                                                    {loadingCarModelYears &&
                                                        <svg className='svgSpinner' viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
                                                            <circle class="spin" cx="400" cy="400" fill="none"
                                                                r="180" stroke-width="39" stroke="#E387FF"
                                                                stroke-dasharray="860 1400"
                                                                stroke-linecap="round" />
                                                        </svg>}
                                                </label>
                                                <select id="carYear" value={selectedYear} onChange={handleYearChange}>
                                                    <option value="">{t('form.yearOption')}</option>
                                                    {loadingCarModelYears === false && carModelYears && carModelYears.map((year, index) => (
                                                        <option key={index} value={year.year}>
                                                            {year.year}


                                                        </option>
                                                    ))}
                                                </select>
                                            </div>}
                                    </div>

                                    {/*--------------------------------------------BBBBBBBBBBBBBB----------------------------------*/}

                                    {selectedYear && (
                                        <div ref={mileageRef} className="question-container wider">
                                            <div className="slider-group" id="carMileage">
                                                <img src={mileage} alt="Mileage Icon" className="mileage-icon" />
                                                <label
                                                    htmlFor="carMileage">{t('form.carMileageLabel')}
                                                </label>
                                                <div className="selected-mileage-box">
                                                    <input
                                                        type="text"
                                                        id="selectedMileage"
                                                        name="mileage"
                                                        placeholder="000" // Placeholder text goes here
                                                        value={inputMileage}
                                                        onChange={handleMileageChange}
                                                        maxLength="3"
                                                    />
                                                    <span>, 000 km</span>

                                                </div>
                                                {/* <div className="slider-container">
                                                    <input
                                                        type="range"
                                                        id="carMileageInput"
                                                        value={isMileageUnknown ? 400 : inputMileage}
                                                        min={0}
                                                        max={400}
                                                        onChange={handleMileageChange}
                                                        onMouseUp={handleMileageMouseUp}
                                                        onTouchEnd={handleMileageTouchEnd}
                                                    />
                                                </div> */}

                                            </div>
                                            {/* <div className="skip-checkbox mileage">
                                                <button
                                                    className={isMileageUnknown ? 'light-grey-button selected' : 'light-grey-button'}
                                                    onClick={handleSkipMileageChange}
                                                >
                                                    {t('form.carMileageUnknown')}
                                                </button>
                                            </div> */}
                                        </div>
                                    )}

                                    {((selectedMileage !== 0 && selectedMileage !== null) || isMileageUnknown) && (
                                        <div ref={postalCodeRef} className="question-container">
                                            <img src={location} alt="Location Icon" className="location-icon" />
                                            <label htmlFor="postalCode">{t('form.postalCodeLabel')}</label>
                                            <input
                                                className="text-box postal-code-input"
                                                type="text"
                                                id="postalCode"
                                                value={postalCode}
                                                placeholder="___ ___"
                                                onChange={handlePostalCodeChange}
                                            // onBlur={e => {
                                            //     const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
                                            //     if (!value.match(/^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/)) {
                                            //         // alert(t('form.validPostalAlert'));
                                            //     }
                                            // }}
                                            />
                                        </div>
                                    )}

                                    {postalCode && postalCode.length >= 7 && (
                                        <div ref={selectedOwnershipRef} className="question-container">
                                            <img src={registration} alt="Registration Icon" className="registration-icon" />

                                            <label htmlFor="carOwnership">{t('form.carOwnershipLabel')}</label>

                                            <div className="button-container">
                                                <button
                                                    value="clean"
                                                    onClick={handleOwnershipChange}
                                                    className={selectedOwnership === "clean" ? 'selected' : ''}
                                                >
                                                    {t('form.ownershipOptionClean')}
                                                </button>

                                                <button
                                                    value="salvaged"
                                                    onClick={handleOwnershipChange}
                                                    className={selectedOwnership === "salvaged" ? 'selected' : ''}
                                                >
                                                    {t('form.ownershipOptionSalvaged')}
                                                </button>

                                                <button
                                                    value="no_title"
                                                    onClick={handleOwnershipChange}
                                                    className={selectedOwnership === "no_title" ? 'selected' : ''}
                                                >
                                                    {t('form.ownershipOptionNoTitle')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {/*--------------------------------------------CCCCCCCCCCCCCCCCCC----------------------------------*/}

                                    {selectedOwnership && selectedOwnership !== 'no_title' && (
                                        <div ref={selectedTireConditionRef} className="question-container">
                                            <img src={flatTire} alt="Flat tire Icon" className={"flat-icon"} />
                                            <label
                                                htmlFor="tireCondition"> {t('form.tireConditionLabel')}
                                            </label>
                                            <div className="button-container">
                                                {[{ value: 'tires_good', desc: 'yesTires' }, { value: 'tires_flat', desc: 'tiresFlat' }, { value: 'tires_missing', desc: 'tiresMissing' }].map(state => (
                                                    <button
                                                        key={state.value}
                                                        value={state.value}
                                                        onClick={handleTireConditionChange}
                                                        className={`button ${state.value === selectedTireCondition ? 'selected' : ''}`}
                                                    >
                                                        {t(`form.${state.desc}`)}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {selectedTireCondition && selectedTireCondition !== 'tires_missing' && (
                                        <div ref={wheelTypeRef} className="question-container">
                                            <img src={rims} alt="Rims Icon" className={"rims-icon"} />
                                            <label htmlFor="alloyOrSteel">{t('form.wheelTypeQuestion')}</label>
                                            <div className="imaged-button-container">
                                                <button
                                                    className={alloyOrSteel === 'alloy' ? 'selected' : ''}
                                                    onClick={() => changeWheel('alloy')}>
                                                    <img src={alloy} alt="Alloy Wheel Icon" className="alloy-icon" />
                                                    {t('form.alloyWheel')}
                                                </button>
                                                <button
                                                    className={alloyOrSteel === 'steel' ? 'selected' : ''}
                                                    onClick={() => changeWheel('steel')}>
                                                    <img src={steel} alt="Steel Wheel Icon" className="steel-icon" />
                                                    {t('form.steelWheel')}
                                                </button>
                                            </div>
                                            <div className="skip-checkbox">
                                                <button
                                                    className={isWheelQuestionSkipped ? 'light-grey-button selected' : 'light-grey-button'}
                                                    onClick={() => changeWheel('')}
                                                >
                                                    {t('form.skipWheelQuestion')}
                                                </button>
                                            </div>

                                        </div>
                                    )}

                                    {(typeof alloyOrSteel === 'string' || selectedTireCondition === 'tires_missing') && (
                                        <div ref={selectedWorkingConditionRef} className="question-container">
                                            <img src={carkeys} alt="Car Keys Icon" className="carkeys-icon" />

                                            <label htmlFor="workingCondition">{t('form.workingConditionLabel')}</label>

                                            <div className="button-container">
                                                <button
                                                    value="starts_drives"
                                                    onClick={handleWorkingConditionChange}
                                                    className={selectedWorkingCondition === "starts_drives" ? 'selected' : ''}
                                                >
                                                    {t('form.workingConditionOptionStartsDrives')}
                                                </button>

                                                <button
                                                    value="starts_cannot_drive"
                                                    onClick={handleWorkingConditionChange}
                                                    className={selectedWorkingCondition === "starts_cannot_drive" ? 'selected' : ''}
                                                >
                                                    {t('form.workingConditionOptionStartsCannotDrive')}
                                                </button>

                                                <button
                                                    value="does_not_start"
                                                    onClick={handleWorkingConditionChange}
                                                    className={selectedWorkingCondition === "does_not_start" ? 'selected' : ''}
                                                >
                                                    {t('form.workingConditionOptionDoesNotStart')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {selectedWorkingCondition && (
                                        <div ref={transmissionRef} className="question-container">
                                            <img src={gear} alt="transmisison Icon" className="transmission-icon" />
                                            <label htmlFor="TransmissionType">
                                                {t('form.carTransmissionQuestion')}
                                            </label>
                                            <div className="imaged-button-container">
                                                <button
                                                    className={transmissionType === 'automatic' ? 'selected' : ''}
                                                    onClick={() => changeTransmission('automatic')}>
                                                    <img src={automatic} alt="automatic transmission Icon" className="automatic-icon" />
                                                    {t('form.automatic')}
                                                </button>
                                                <button
                                                    className={transmissionType === 'manual' ? 'selected' : ''}
                                                    onClick={() => changeTransmission('manual')}>
                                                    <img src={manual} alt="manual transmission Icon" className="manual-icon" />
                                                    {t('form.manual')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {transmissionType && (
                                        <div ref={doesRunRef} className="question-container">
                                            <img src={gearCheck} alt="checked Gear Icon" className="runcondition-icon" />
                                            <label htmlFor="DoesRun">
                                                {t('form.doesRunQuestion')}
                                            </label>
                                            <div className="button-container">
                                                <button
                                                    className={doesRun === 'runs_fine' ? 'selected' : ''}
                                                    onClick={() => changeDoesRun('runs_fine')}>
                                                    {t('form.doesRun')}
                                                    <img />
                                                </button>
                                                <button
                                                    className={doesRun === 'engine_issue' ? 'selected' : ''}
                                                    onClick={() => changeDoesRun('engine_issue')}>
                                                    {t('form.runEngineIssue')}
                                                    <img src={checkEngine} alt="check engine icon" className="checkengine-icon" />
                                                    <img />
                                                </button>
                                                <button
                                                    className={doesRun === 'transmission_issue' ? 'selected' : ''}
                                                    onClick={() => changeDoesRun('transmission_issue')}>
                                                    {t('form.runTransmissionIssue')}
                                                    <img src={checkTransmission} alt="check transmission icon" className="checktransmission-icon" />
                                                    <img />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {doesRun && (
                                        <div ref={hasDamageRef} className="question-container">
                                            <img src={cleancardoor} alt="No Body Damage Icon" className="cleancardoor-icon" />

                                            <label htmlFor="carDamage">
                                                {t('form.carDamageQuestion')}
                                            </label>
                                            <div className="button-container">
                                                <button
                                                    className={hasDamage === 'no_damage' ? 'selected' : ''}
                                                    onClick={() => handleChangeDamage('no_damage')}>
                                                    {t('form.noHasDamage')}
                                                </button>
                                                <button
                                                    className={hasDamage === 'body_damage' ? 'selected' : ''}
                                                    onClick={() => handleChangeDamage('body_damage')}>
                                                    {t('form.yesHasDamage')}
                                                </button>
                                            </div>
                                        </div>
                                    )}


                                    {hasDamage === 'body_damage' && (
                                        <div ref={hasFloodFireRef} className="question-container carDamage">
                                            <img src={brokencar} alt="broken car" className="brokencar-icon" />
                                            <label htmlFor="FloodOrFire">
                                                {t('form.carFloodOrFireQuestion')}
                                            </label>
                                            <div className="button-container">
                                                <button
                                                    className={hasFloodOrFire === 'no_damage' ? 'selected' : ''}
                                                    onClick={() => changeFloodFire('no_damage')}>
                                                    {t('form.noFloodFire')}
                                                </button>
                                                <button
                                                    className={hasFloodOrFire === 'has_accident' ? 'selected' : ''}
                                                    onClick={() => { changeFloodFire('has_accident') }}>
                                                    {t('form.yesAccident')}
                                                    <img src={accident} alt="car collision" className="collision-icon" />
                                                </button>
                                                {/* <button
                                                    className={hasFloodOrFire === 'has_fire_damage' ? 'selected' : ''}
                                                    onClick={() => { changeFloodFire('has_fire_damage') }}>
                                                    {t('form.yesFire')}
                                                    <img src={carfire} alt="car on fire" className="fire-icon" />
                                                </button>
                                                <button
                                                    className={hasFloodOrFire === 'has_flood_damage' ? 'selected' : ''}
                                                    onClick={() => { changeFloodFire('has_flood_damage') }}>
                                                    {t('form.yesFlood')}
                                                    <img src={flood} alt="car Flood Icon" className="flood-icon" />
                                                </button> */}
                                            </div>
                                        </div>
                                    )}


                                    {(hasFloodOrFire === 'has_accident') && (
                                        <div ref={airbagRef} className="question-container">

                                            <img src={airbagIcon} alt="Airbag icon" style={{ width: "48px", height: "48px" }} />
                                            <label htmlFor="airbag">{t('form.airbag')}</label>

                                            <div className="button-container">

                                                <button
                                                    className={airbag === false ? "selected" : ""}
                                                    id="hasAirbag"
                                                    value="false"
                                                    onClick={() => handleChangeAirbag(false)}
                                                >
                                                    {t('form.airbagfine')}
                                                </button>

                                                <button
                                                    className={airbag === true ? "selected" : ""}
                                                    id="airbagMiss"
                                                    value="true"
                                                    onClick={() => handleChangeAirbag(true)}
                                                >
                                                    {t('form.airbagnotfine')}
                                                    <img src={collision} alt="car collision" className="collision-icon" />
                                                </button>

                                            </div>
                                        </div>
                                    )}

                                    {((hasFloodOrFire === 'has_accident' && (airbag !== null))) && (
                                        <div ref={uploadPicRef} className="question-container">

                                            <img src={damageCamera} alt="uploadPic" style={{ height: "55px", width: "55px" }} />

                                            <label htmlFor="uploadPic">
                                                {t('form.uploadPic')}
                                            </label>
                                            <div className="button-container">
                                                <button
                                                    className={unableToUpload ? 'selected' : ''}
                                                    style={{ marginBottom: "11px" }}
                                                    onClick={onClickUnableToUpload}>
                                                    {t('form.unableUploadPic')}
                                                </button>
                                                <ImageUploader imageChanged={onUploadImage} />
                                            </div>
                                        </div>
                                    )}
                                    {((hasDamage === 'body_damage' && (unableToUpload || uploadImages.current.length > 0) && hasFloodOrFire !== null) || (hasDamage === 'no_damage') || (hasFloodOrFire === 'no_damage')) && (
                                        <div ref={selectedMissingPartsRef} className="question-container">

                                            <img src={missingparts} alt="Missing Parts Icon" style={{ height: "78px", width: "156px" }} className="missingparts-icon" />
                                            <label htmlFor="missingParts">{t('form.missingParts')}</label>

                                            <div className="button-container">
                                                <button
                                                    className={selectedMissingParts.includes("nothingMissing") ? "selected" : ""}
                                                    id="nothingMissing"
                                                    value="nothingMissing"
                                                    onClick={handleMissingPartsChange}
                                                >
                                                    {t('form.missingPartsNone')}
                                                    <img src={accept} alt="No Missing Parts Icon" className="checkmark-icon" />
                                                </button>

                                                <button
                                                    className={selectedMissingParts.includes("catalyserMissing") ? "selected" : ""}
                                                    id="missingPartsCatalyticConverter"
                                                    value="catalyserMissing"
                                                    onClick={handleMissingPartsChange}
                                                >
                                                    {t('form.missingPartsCatalyticConverter')}
                                                    <img src={catalyticConverter} alt="Catalytic Converter Icon" className="checkmark-icon" />
                                                </button>

                                                <button
                                                    className={selectedMissingParts.includes("otherPartsMissing") ? "selected" : ""}
                                                    id="missingPartsEngine"
                                                    value="otherPartsMissing"
                                                    onClick={handleMissingPartsChange}
                                                >
                                                    {t('form.missingPartsOther')}
                                                </button>

                                            </div>
                                        </div>
                                    )}

                                    {/*--------------------------------------------DDDDDDDDDDDDDDDD----------------------------------*/}

                                    {selectedMissingParts.length > 0 && (hasDamage === 'no_damage' || hasFloodOrFire === 'no_damage' || ((hasFloodOrFire === 'has_accident' && hasDamage === 'body_damage' && airbag !== null && (uploadImages.current.length > 0 || unableToUpload)) || (hasFloodOrFire === 'no_damage' && hasDamage === 'body_damage' && airbag === null && (uploadImages.current.length > 0 || unableToUpload)))) && (
                                        <div ref={namePhoneContainerRef} className="info-box">
                                            <h2 className="box-title middle"> {t('form.lastStepTitle')}</h2>
                                            <div className={`namePhone-container`}>
                                                {/* Form Section */}
                                                <section className="name-section">
                                                    <label
                                                        htmlFor="firstName"
                                                        className="firstName-label" >{t('form.name')}
                                                        <img src={name} alt="Name Icon" className="name-icon" />
                                                    </label>
                                                    <input
                                                        className="name-text-box"
                                                        type="text"
                                                        id="firstName"
                                                        value={firstName}
                                                        onChange={handleFirstNameChange}
                                                    />
                                                </section>

                                                <section className="phone-section">
                                                    <label
                                                        htmlFor="phoneNumber"
                                                        className="phoneNumber-label" >{t('form.phoneNumber')}
                                                        <img src={telephone} alt="Name Icon" className="telephone-icon" />
                                                    </label>
                                                    <InputMask
                                                        mask="(999) 999-9999"
                                                        className="phone-text-box"
                                                        id="phoneNumber"
                                                        value={phoneNumber}
                                                        type="text"
                                                        onChange={handlePhoneNumberChange}
                                                        placeholder="(___) ___-____"
                                                    >
                                                        {(inputProps) => <input {...inputProps} />}
                                                    </InputMask>
                                                    <ul style={{
                                                        padding: 0,
                                                        fontWeight: 500,
                                                        fontSize: '18px'
                                                    }}>
                                                        <li>
                                                            <span>{t('form.phoneNumberHint1')}</span>

                                                        </li>
                                                        <li style={{ marginTop: "20px" }}>
                                                            <span>{t('form.phoneNumberHint2')}</span>

                                                        </li>
                                                    </ul>
                                                </section>
                                            </div>
                                        </div>
                                    )}

                                    {/*--------------------------------------------EEEEEEEEEEEE----------------------------------*/}

                                    {/* Calculate Offered Price button */}
                                    <div ref={calculateOfferRef} className="calculate-button-container">
                                        {selectedMissingParts.length > 0 && (hasDamage === 'no_damage' || ((hasFloodOrFire === 'has_accident' && hasDamage === 'body_damage' && airbag !== null && (uploadImages.current.length > 0 || unableToUpload)) || (hasFloodOrFire === 'no_damage' && hasDamage === 'body_damage' && airbag === null) || (hasFloodOrFire === 'no_damage' && hasDamage === 'body_damage' && airbag === null && (uploadImages.current.length > 0 || unableToUpload)))) && (
                                            <div>
                                                <button
                                                    className={`calculate-button ${isButtonClicked ? 'clicked' : ''}`}
                                                    onClick={calculateOfferedPrice}
                                                    onMouseDown={() => setIsButtonClicked(true)}
                                                    onMouseUp={() => setIsButtonClicked(false)}
                                                >
                                                    {t('form.calculateOfferedPrice')}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {/*--------------------------------------------FFFFFFFFFFFFFF----------------------------------*/}

                                    <>
                                        {(loadingHighestOffer || (offeredPrice !== null)) && (
                                            <section>
                                                <div ref={acceptOfferRef} className="offer-display-container">
                                                    <div className="offer-text-and-notes-container">

                                                        {offeredPrice === null ? (
                                                            <div className='loading'>
                                                                <span className="gear">⚙️</span>
                                                                {t('form.loading')}
                                                                <span className="gear">⚙️</span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <p className="offer-text" style={{ textAlign: 'center' }} aria-label={t('loading')}>
                                                                    {t('form.offer', { offeredPrice: `$${Math.floor(offeredPrice)}` })}
                                                                </p>
                                                                <ul className="offer-notes">
                                                                    <li className={showItem1 ? "show-item-1" : ""}>
                                                                        {t('form.offerNotesItem1.1')}
                                                                        <span className="underline"> {formattedDate} </span>
                                                                        {t('form.offerNotesItem1.2')}
                                                                    </li>
                                                                    <li className={showItem2 ? "show-item-2" : ""}>
                                                                        {t('form.offerNotesItem2')}
                                                                    </li>
                                                                    <li className={showItem3 ? "show-item-3" : ""}>
                                                                        {t('form.offerNotesItem4')}
                                                                    </li>
                                                                    {clientProvince !== '' ? <li className={showItem4 ? "show-item-4" : ""}>
                                                                        {t('form.offerNotesItem3&1')}
                                                                        {`${clientProvince} `}
                                                                        {t('form.offerNotesItem3&2')}
                                                                        <img
                                                                            src={
                                                                                clientProvince === 'AMA' ? ama :
                                                                                    clientProvince === 'ICBC' ? icbcSmall :
                                                                                        clientProvince === 'MTO' ? mto :
                                                                                            clientProvince === 'SGI' ? sgi :
                                                                                                saaq
                                                                            }
                                                                            alt={
                                                                                clientProvince === 'AMA' ? "ama logo" :
                                                                                    clientProvince === 'ICBC' ? "icbc logo" :
                                                                                        clientProvince === 'MTO' ? "mto logo" : // Alt text for the MTO logo
                                                                                            "saaq logo"
                                                                            }
                                                                            className={
                                                                                clientProvince === 'AMA' ? "ama-icon" :
                                                                                    clientProvince === 'ICBC' ? "icbc-icon" :
                                                                                        clientProvince === 'MTO' ? "mto-icon" : // Class name for the MTO logo
                                                                                            "saaq-icon"
                                                                            }
                                                                        />
                                                                    </li> : null}



                                                                </ul>
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className="buttons-container">
                                                        <div className="button-with-text-container">
                                                            <button
                                                                className="accept-button"
                                                                onClick={() => {
                                                                    document.querySelector('.suggest-price-button').classList.remove('clicked');
                                                                    document.querySelector('.accept-button').classList.add('clicked');
                                                                    handleAcceptOffer();
                                                                }}
                                                            >
                                                                {t('form.acceptOffer')}
                                                            </button>
                                                            <div className="offer-description">
                                                                {t('form.acceptOfferDescriptionStart')} {getEarliestPickupDate() === 'today' ? t('form.today') : t('form.tomorrow')} 📅
                                                                <br />
                                                                {t('form.acceptOfferDescriptionEnd')}
                                                            </div>
                                                        </div>
                                                        <div className="button-with-text-container">
                                                            <button
                                                                className="suggest-price-button"
                                                                onClick={() => {
                                                                    document.querySelector('.accept-button').classList.remove('clicked');
                                                                    document.querySelector('.suggest-price-button').classList.add('clicked');
                                                                    handleSuggestPrice();
                                                                }}
                                                            >
                                                                {t('form.suggestAnotherPrice')}
                                                            </button>
                                                            <div className="offer-description">
                                                                {t('form.suggestPriceDescriptionStart')}
                                                                <br />
                                                                {t('form.suggestPriceDescriptionEnd', { offeredPrice: `${Math.floor(offeredPrice)}` })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        )}
                                    </>

                                    <div>
                                        {isOfferAccepted && validatePhoneNumber(phoneNumber) && (
                                            <div className="offer-response-container">
                                                <h2 className="box-title end">{t('form.acceptOfferFormIntro')}</h2>
                                                <div ref={offerAcceptedRef} className="schedule-and-submit-container">
                                                    <div className="new-question-container" ref={pickupDateRef}>
                                                        <label>
                                                            {t('form.pickupDate')}
                                                            <img src={calendar} alt="Calendar Icon" className="calendar-icon" />
                                                        </label>
                                                        <div className="date-button-container">
                                                            {isToday && (
                                                                <button
                                                                    className={`date-button ${selectedDate === getDateString(0) ? 'date-button-selected' : ''}`}
                                                                    onClick={() => { setSelectedDate(getDateString(0)); setShowDatePicker(false); }}
                                                                >
                                                                    {t('form.today')}
                                                                </button>
                                                            )}
                                                            <button
                                                                className={`date-button ${selectedDate === getDateString(1) ? 'date-button-selected' : ''}`}
                                                                onClick={() => { setSelectedDate(getDateString(1)); setShowDatePicker(false); }}
                                                            >
                                                                {t('form.tomorrow')}
                                                            </button>
                                                            {showDatePicker ? (
                                                                <input
                                                                    className="input-date"
                                                                    type="date"
                                                                    min={getDateString(2)}
                                                                    value={selectedDate}
                                                                    onChange={(e) => { console.log(e.target.value); setSelectedDate(e.target.value) }}
                                                                />
                                                            ) : (
                                                                <button
                                                                    className={`date-button ${selectedDate && selectedDate !== getDateString(0) && selectedDate !== getDateString(1) ? 'date-button-selected' : ''}`}
                                                                    onClick={() => setShowDatePicker(true)}
                                                                >
                                                                    {t('form.chooseALaterDate')}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {selectedDate && (
                                                        <div className="new-question-container" ref={pickupTimeRef}>
                                                            <label
                                                                htmlFor="pickup-time">{t('form.pickupTime')}
                                                                <img src={clock} alt="Clock Icon" className="clock-icon" />
                                                            </label>
                                                            <div className="time-button-container">
                                                                {/* Render the "morning" button if the user didn't select "today" */}
                                                                {(selectedDate !== getDateString(0) || currentHour < 11) && (
                                                                    <button
                                                                        className={`time-button ${selectedTime.dayTime === 'morning' ? 'time-button-selected' : ''}`}
                                                                        onClick={() => setSelectedTime({ from: '08:00', to: '12:00', dayTime: 'morning' })}
                                                                    >
                                                                        {t('form.morning')}

                                                                    </button>
                                                                )}

                                                                {/* Render the "afternoon" button if the user didn't select "today" */}
                                                                {(selectedDate !== getDateString(0) || currentHour < 15) && (
                                                                    <button
                                                                        className={`time-button ${selectedTime.dayTime === 'afternoon' ? 'time-button-selected' : ''}`}
                                                                        onClick={() => setSelectedTime({ from: '12:00', to: '16:00', dayTime: 'afternoon' })}
                                                                    >
                                                                        {t('form.afternoon')}
                                                                    </button>
                                                                )}

                                                                {selectedDate !== getDateString(0)}
                                                                <button
                                                                    className={`time-button ${selectedTime.dayTime === 'evening' ? 'time-button-selected' : ''}`}
                                                                    onClick={() => setSelectedTime({ from: '16:00', to: '20:00', dayTime: 'evening' })}
                                                                >
                                                                    {t('form.evening')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {selectedTime.dayTime !== '' && (
                                                        <div className="new-question-container">
                                                            <label htmlFor="address">{t('form.address')}
                                                                <img src={googleMaps} alt="Google Maps Icon" className="googleMaps-icon" />
                                                            </label>
                                                            <input
                                                                className="input-address"
                                                                type="text"
                                                                id="address"
                                                                value={address}
                                                                onChange={handleAddressChange} // Use the event handler here
                                                            />
                                                            {suggestions && suggestions.length > 0 && (
                                                                <ul className="suggestions-dropdown">
                                                                    {suggestions.slice(0, 3).map((suggestion) => (
                                                                        <li
                                                                            key={suggestion.place_id}
                                                                            onClick={() => handleSuggestionClick(suggestion)}
                                                                        >
                                                                            {suggestion.description}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    )}

                                                    {address && address.length >= 5 && address.includes(' ') && (
                                                        <div ref={phoneVerificationRef} className="visual-phone-verification">
                                                            <div className="phone-confirmation-box">
                                                                <p>{t('form.phoneNumberVerification1')}</p>
                                                                <div className="show-phone-number">
                                                                    {phoneNumber}
                                                                </div>
                                                                <p>{t('form.phoneNumberVerification2')}</p>
                                                            </div>
                                                            <button
                                                                className="submit-button"
                                                                onClick={handleSubmitAccept}
                                                            >
                                                                {t('form.submit')}
                                                            </button>
                                                        </div>
                                                    )}

                                                    {isLoadingScreen && (
                                                        <div className="loading-screen">
                                                            <div className="loading-overlay"></div>
                                                            <div className="loading-container">
                                                                <div className="loading-content">
                                                                    <div className='loading'>
                                                                        <span className="gear">⚙️</span>
                                                                        {t('form.loadingSubmission')}
                                                                        <span className="gear">⚙️</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        )}

                                        {isPriceSuggested && validatePhoneNumber(phoneNumber) && (
                                            <div className="offer-response-container">
                                                <h2 ref={offerSuggestedRef} className="box-title end">{t('form.rejectOfferFormIntro')}</h2>

                                                <div className="new-question-container rejectResponseBox">
                                                    <div ref={rejectResponseBoxRef}></div>

                                                    <>
                                                        <div className="desired-price-container">
                                                            <label htmlFor="desired-price">{t('form.desiredPrice')}</label>
                                                            <input
                                                                className="desired-price-text-box-container"
                                                                id="desired-price"
                                                                placeholder="$"
                                                                min="0"
                                                                value={desiredPrice}
                                                                onChange={handlePriceChange}
                                                            />
                                                        </div>

                                                        {/* only shows the submit section if the desired price is greater than the offered price */}
                                                        {desiredPrice >= offeredPrice && (

                                                            <div className="visual-phone-verification">
                                                                <div className="phone-confirmation-box">
                                                                    <p>{t('form.phoneNumberVerification1')}</p>
                                                                    <div className="show-phone-number">
                                                                        {phoneNumber}
                                                                    </div>
                                                                </div>

                                                                <button
                                                                    className="submit-button"
                                                                    onClick={handleSubmitReject}
                                                                >
                                                                    {t('form.submit')}
                                                                </button>

                                                            </div>

                                                        )}

                                                        {isLoadingScreen && (
                                                            <div className="loading-screen">
                                                                <div className="loading-overlay"></div>
                                                                <div className="loading-container">
                                                                    <div className="loading-content">
                                                                        <div className='loading'>
                                                                            <span className="gear">⚙️</span>
                                                                            {t('form.loadingSubmission')}
                                                                            <span className="gear">⚙️</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div>
                                                            <div className="fullReject-container">
                                                                <label>
                                                                    {t('form.fullRejectOfferLabel')}
                                                                </label>
                                                                <button
                                                                    className="full-reject-button" onClick={() => { handleFullRejectOffer(); resetForm(); }}>{t('form.fullRejectOffer')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>


                                                </div>



                                            </div>
                                        )}

                                    </div>



                                    {isFormSubmitted && (
                                        <>
                                            <div ref={whatHappensNextRef} className="what-happens-next-header">
                                                <h2>
                                                    {t('form.whatHappensIfAccept')}
                                                </h2>
                                            </div>
                                            <div className="steps-container">
                                                <div className="steps-container-widescreen-modifier">

                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 5</div>
                                                        <h2>{t('form.steps.step5.title')}</h2>
                                                        <div>
                                                            <img src={step5} alt="automatic text message" className="step5-image" />
                                                            <p>{replaceEmojis(t('form.steps.step5.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 6</div>
                                                        <h2>{t('form.steps.step6.title')}</h2>
                                                        <div>
                                                            <img src={step6} alt="get a call from a specialist" className="step6-image" />
                                                            <p>{replaceEmojis(t('form.steps.step6.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 7</div>
                                                        <h2>{t('form.steps.step7.title')}</h2>
                                                        <div>
                                                            <img src={step7} alt="registration keys and driver's license" className="step7-image" />
                                                            <p>{replaceEmojis(t('form.steps.step7.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="step">
                                                        <div className="step-number">{t('form.steps.header')} 8</div>
                                                        <h2>{t('form.steps.step8.title')}</h2>
                                                        <div>
                                                            <img src={step8} alt="scrap car sold" className="step8-image" />
                                                            <p>{replaceEmojis(t('form.steps.step8.description'), emojiMapping)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )
                                    }


                                </div>
                            } />
                            {/* Book Towtruck Route */}
                            <Route path="book-your-towtruck" element={<BookTowtruck />} />
                            <Route path="highest-offer-price" element={<VehicleProfileHash />} />
                            {/* <Route path="privacy-policy" element={<PrivacyAndPolicy />} /> */}
                            <Route path="blog" element={<Blog scrollToStartBox={scrollToStartBox} scrollToHowItWorks={scrollToStepsContainer} />} />
                            <Route path="blog/:postId" element={<BlogPost />} />
                        </Route>
                        {/* Buyer Routes */}
                        {/* <Route path="/buyer" element={<BuyerLayout />}>
                            <Route path="dispatcher-dash" element={<DispatcherDash />} />
                            Add other buyer routes here
                        </Route> */}
                    </Routes>
                </LocationProvider>
            </Router >
        </>
    );
}

export default GetOffer;