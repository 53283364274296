import config from "../index";

const customFetcher = (url, init) => {
    // const token = getData(storageKeys.token);
    const controller = new AbortController();
    //const timeout = setTimeout(() => controller.abort(), 5000);
    // debugger
    console.log(config)
    return fetch(`${config.apiUrl}${url}`, {
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Credentials": "*",
            // "Access-Control-Allow-Origin": "*"
            // ...(token ? {
            //     Authorization: `Bearer ${token}`
            // } : {}),
        },
        //signal: controller.signal,
        ...init
    });
}
export default customFetcher;