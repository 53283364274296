import React, { useState, useContext, useEffect } from 'react';
import { LocationContext } from '../../contexts/LocationContext';
import ContainerSelector from "../../components/buyer/ContainerSelector";
import CarContainer from "../../components/buyer/CarContainer";
import './DispatcherDash.css';

const DispatcherDash = () => {
    const { location } = useContext(LocationContext);

    const [vehicleProfile, setVehicleProfile] = useState([]);
    const [topNavStatus, setTopNavStatus] = useState('toConfirm');
    const [selectedBuyer, setSelectedBuyer] = useState('');

    const fetchCarData = async () => {
        const proxyData = [
            {
                model: '2008 Accord',
                year: '2008',
                price: '$380',
                distance: '204 km',
                date: '4 Feb',
                time: '4pm-8pm',
                postcode: 'Longueuil',
                phone: '(438) 346-9331',
                assignedBuyer: 'Buyer 1',
                assignedTowTruck: 'Truck 1',
                appointmentStart: '4pm',
                appointmentEnd: '8pm',
                pickupAddress: '123 Main St, Longueuil',
            },
            {
                model: '2015 Camry',
                year: '2015',
                price: '$420',
                distance: '189 km',
                date: '6 Feb',
                time: '3pm-7pm',
                postcode: 'St.-Eustache',
                phone: '(604) 987-6543',
                assignedBuyer: 'Buyer 2',
                assignedTowTruck: 'Truck 2',
                appointmentStart: '3pm',
                appointmentEnd: '7pm',
                pickupAddress: '456 Elm St, St.-Eustache',
            },
            // Add more cars as needed for testing
        ];
        setVehicleProfile(proxyData)
    };

    useEffect(() => {
        fetchCarData();
    }, [selectedBuyer, topNavStatus]);

    return (
        <div className="parent-div">
            <div className="dispatcher-dash">
                <ContainerSelector
                    topNavStatus={topNavStatus}
                    setTopNavStatus={setTopNavStatus}
                    selectedBuyer={selectedBuyer}
                    setSelectedBuyer={setSelectedBuyer}
                />
                <div className="car-container-wrapper">
                    {vehicleProfile.map((car, index) => (
                        <CarContainer
                            key={index}
                            model={car.model}
                            price={car.price}
                            distance={car.distance}
                            date={car.date}
                            time={car.time}
                            postcode={car.postcode}
                            phone={car.phone}
                            assignedBuyer={car.assignedBuyer}
                            assignedTowTruck={car.assignedTowTruck}
                            appointmentStart={car.appointmentStart}
                            appointmentEnd={car.appointmentEnd}
                            pickupAddress={car.pickupAddress}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DispatcherDash;
