/* global google */
import React, { useState, useEffect, useRef } from 'react';
import '../GetOffer/GetOffer.css';
import greyCursor from '../../img/greyCursor128.png';
import './PrivacyAndPolicy.css';
import { useTranslation } from 'react-i18next';


function PrivacyAndPolicy() {

    const { t, i18n } = useTranslation();
    return (

        <div class="container">
            <div class="content">
                <h1>Privacy and Policy</h1>
                <p>
        
                </p>
            </div>
            <div class="footer">
                <div className="quick-description-container">
                    <div className={`quick-description2`}>
                        {t('header.subTitleStatement')}
                    </div>
                    <button
                        className={`instruction-button orange-button`}
                    >
                        {t('intro.instructions')}
                        <img src={greyCursor} alt="Grey Cursor" className="cursor1" />
                    </button>
                    <button
                        className={`instruction3-button`}
                    >
                        {t('intro.instructions2')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PrivacyAndPolicy;