// AboutUsWrapper.js

import React from 'react';
import AboutUs from '../pages/AboutUs/AboutUs';

const AboutUsWrapper = ({ setShouldScrollToStartBox, shouldScrollToStartBox, selectedLanguage }) => {
    return (
        <AboutUs
            setShouldScrollToStartBox={setShouldScrollToStartBox}
            shouldScrollToStartBox={shouldScrollToStartBox}
            selectedLanguage={selectedLanguage}
        />
    );
};

export default AboutUsWrapper;
